export default {
  common: {
    noData: 'Keine Daten',
    noDataFound: 'Keine Daten gefunden',
    topic: 'Thema',
    noTopic: 'Kein Thema',
    noTopicSelected: 'Kein Thema ausgewählt',
    noFile: 'Keine Datei gefunden',
    noFiles: 'Keine Dateien vorhanden',
    deleteFile: 'Datei löschen',
    cancel: 'Abbrechen',
    save: 'Speichern',
    required: 'Pflichtfeld',
    requiredShort: 'Pflicht',
    requiredInvalid: 'Pflichtfeld muss ausgefüllt werden',
    inputMaxNChars: 'Eingabe maximal {n} Zeichen',
    inputTooLong: 'Eingabe zu lang',
    send: 'Versenden',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    repeat: 'Wiederholen',
    doRepeat: 'Wiederhole',
    repetition: 'Wiederholung',
    close: 'Schließen',
    serverError: 'Ein Serverfehler ist aufgetreten',
    serverConnectionError: 'Verbindung mit dem Server konnte nicht hergestellt werden',
    contact: 'Kontakt',
    imprint: 'Impressum',
    privacy: 'Datenschutz',
    password: 'Passwort',
    showAll: 'Zeige alle',
    start: 'Start',
    startTime: 'Startzeit',
    duration: 'Dauer',
    customer: 'Kunde',
    company: 'Firma',
    username: 'Benutzername',
    limit: 'Flat',
    type: 'Typ',
    action: 'Aktion',
    actions: 'Aktionen',
    settings: 'Einstellungen',
    participants: 'Teilnehmer',
    moderators: 'Moderatoren',
    active: 'Aktiv',
    inactive: 'Inaktiv',
    activated: 'Aktiviert',
    deactivated: 'Deaktiviert',
    all: 'Alle',
    end: 'Beenden',
    sidebar: 'Sidebar',
    services: 'Services',
    inWork: 'in Arbeit',
    conference: 'Konferenz',
    share: 'Teilen',
    german: 'Deutsch',
    english: 'Englisch',
    sendMail: 'Mail senden',
    sendTestMail: 'Testmail senden',
    responseAdress: 'Antwort E-mail',
    attachments: 'Anhang',
    select: 'Auswählen',
    name: 'Name',
    phone: 'Telefon-Nr',
    phoneShort: 'Telefon',
    phoneNumber: 'Telefonnummer',
    phoneMobile: 'Telefon (mobil)',
    mobile: 'Mobile',
    fax: 'Fax',
    status: 'Status',
    recording: 'Aufzeichnung',
    recordings: 'Aufzeichnungen',
    deleteAfter: 'Autom. Löschung nach {days} Tagen',
    download: 'Download',
    email: 'E-Mail',
    mail: 'Mail',
    add: 'Hinzufügen',
    language: 'Sprache',
    none: 'keine',
    none2: 'keinem',
    begin: 'Beginn',
    until: 'Bis zum',
    from: 'von',
    to: 'bis',
    summary: 'Zusammenfassung',
    back: 'Zurück',
    notice: 'Hinweis',
    confirm: 'Bestätigen',
    optional: 'optional',
    phoneInvalid: 'Bitte überprüfen Sie die Telefonnummer',
    numberExists: 'Telefonnummer existiert bereits',
    emailInvalid: 'Bitte überprüfen Sie die E-Mail',
    emailExists: 'E-Mail existiert bereits',
    dateCreated: 'Erstellungsdatum',
    selectLanguage: 'Sprache auswählen',
    atThe: 'am',
    at: 'um',
    dialIn: 'Einwahl',
    selectFile: 'Datei auswählen',
    verify: 'Überprüfen',
    import: 'Importieren',
    or: 'oder',
    minutes: 'Min',
    nMinutes: '{number} Min',
    minutesLong: 'Minuten',
    hours: 'Stunden',
    survey: 'Umfrage',
    appointment: 'Termin',
    appointmentDate: 'Termin',
    registered: 'registriert',
    expected: 'erwartet',
    fullname: 'Vor- und Nachname',
    date: 'Datum',
    time: 'Uhrzeit',
    preAppointment: 'Vorbesprechung',
    preAppointmentShort: 'Vorb.',
    noConference: 'keine Konferenz',
    regForm: 'Anmeldeformular',
    regForms: 'Anmeldeformulare',
    surveyForm: 'Umfrageformular',
    surveyForms: 'Umfrageformulare',
    management: 'Verwaltung',
    room: 'Konferenzraum',
    rooms: 'Konferenzräume',
    control: 'Steuerung',
    brandings: 'Brandings',
    salutation: 'Anrede',
    title: 'Titel',
    firstname: 'Vorname',
    lastname: 'Nachname',
    street: 'Straße',
    streetAndNumber: 'Straße und Hausnummer',
    streetAndNumberShort: 'Straße und Nummer',
    zipCode: 'Postleitzahl',
    zip: 'PLZ',
    city: 'Ort',
    zipCodeAndCity: 'Postleitzahl und Ort',
    zipAndCity: 'PLZ und Ort',
    country: 'Land',
    timezone: 'Zeitzone',
    mr: 'Herr',
    mrs: 'Frau',
    diverse: 'Andere',
    divers: 'Divers',
    costCenterPreset: 'Vorbelegung der Kostenstelle',
    playback: 'Playback',
    reset: 'zurücksetzen',
    overview: 'Übersicht',
    form: 'Formular',
    forms: 'Formulare',
    backToOverview: 'Zurück zur Übersicht',
    confirmed: 'bestätigt',
    total: 'gesamt',
    setup: 'Setup',
    exists: 'vorhanden',
    points: 'Punkte',
    percent: 'Prozent',
    orMoveHere: 'oder hierher ziehen',
    untilAround: 'bis ca.',
    approx: 'ca. {value}',
    branding: 'Branding',
    keep: 'Bewahren',
    url: 'URL',
    description: 'Beschreibung',
    postCodeAndCity: 'PLZ und Ort',
    postCodeCity: 'PLZ & Ort',
    notes: 'Bemerkungen',
    note: 'Bemerkung',
    on: 'An',
    partnerID: 'Partner-ID',
    callNumber: 'Rufnummer',
    unsaved: 'ungespeichert',
    imageSize: '{x} x {y} Pixel',
    requiredInvalidAndMail: 'Pflichtfeld muss ausgefüllt werden und eine Mail sein.',
    vvId: 'VV-ID',
    comment: 'Kommentar',
    locked: 'gesperrt',
    id: 'ID',
    created: 'Erstellt',
    info: 'Info',
    createdAt: 'erstellt am',
    lock: 'sperren',
    unlock: 'entsperren',
    requiredInvalid2: 'Pflichtfeld, bitte ausfüllen',
    verifyRedFields: 'Überprüfen Sie die rot markierten Felder.',
    anErrorOccurredTryAgain: 'Ein Fehler ist aufgetreten, bitte versuchen Sie es nochmal.',
    anErrorOccurredReloadPage: 'Ein Fehler ist aufgetreten, bitte versuchen Sie es nochmal. Oder laden Sie die Seite neu',
    emailAddress: 'E-Mail Adresse',
    unlimited: 'unbegrenzt',
    products: 'Produkte',
    dialInEn: 'Dial-In',
    dialOut: 'Dial-Out',
    no: 'nein',
    yes: 'ja',
    today: 'heute',
    numberShort: 'Nr.',
    options: 'Optionen',
    gutBeraten: 'Gut beraten',
    and: 'und',
    examples: 'Beispiele',
    w2Access: 'W2 Freigabe',
    address: 'Adresse'
  },
  nav: {
    dashboard: 'Dashboard',
    menu: 'Menü',
    logout: 'Log Out',
    logoutOperator: 'Logout Operator',
    gotAnyQuestions: 'Haben Sie Fragen? Rufen Sie uns an.',
    csnDescription: 'CSN Conferencing ist ein Konferenzdienst der CSN GmbH',
    forms: 'Formulare',
    conferences: 'Konferenzen',
    overviewRegForms: 'Anmeldeformulare',
    overviewSurveyForms: 'Umfrageformulare',
    presentation: 'Präsentation',
    businessCards: 'Visitenkarten',
    service: 'Service',
    operatorServices: 'Operator-Services',
    userManagement: 'Nutzerverwaltung',
    report: 'Report',
    registrations: 'Anmeldungen',
    surveys: 'Umfragen',
    system: 'System',
    admin: 'Admin',
    news: 'News',
    blacklist: 'Teilnehmer-Blacklist',
    pinBlacklist: 'PIN-Blacklist',
    profile: 'Profil',
    supportCenter: 'Support-Center',
    serviceCenter: 'Service-Center',
    login: 'Anmelden',
    loginAs: 'Als {username} einloggen',
    customFields: 'Individuelle Eingabefelder',
    liveVideoBackgrounds: 'Video Hintergründe',
    descriptions: {
      scheduledConferences: 'Konferenzübersicht',
      plannedConferences: 'Konferenzübersicht',
      newConference: 'Konferenz buchen',
      roomConferences: 'Konferenzräume verwalten',
      newConferenceRoom: 'Konferenzraum buchen',
      dashboard: 'Startseite',
      overviewRegForms: 'Teilnehmermanagement',
      newRegForm: 'Anmeldeformular erstellen',
      overviewSurveyForms: 'Tests & Abstimmungen',
      newSurveyForm: 'Umfrageformular erstellen',
      customField: 'optional mit Validierung',
      brandings: 'im eigenen Design',
      newBranding: 'Branding erstellen',
      operatorServices: 'Übersicht der Buchungen',
      bookOperator: 'Operator-Service anfordern',
      adressbook: 'Kontakte & Gruppen',
      playbacks: 'Audiodateien verwalten',
      runningConferencesAdmin: 'Operatorkonsole',
      scheduledConferencesAdmin: 'Konferenzplanung',
      pastConferencesAdmin: 'Konferenzhistorie',
      registrations: 'Teilnehmer Übersicht',
      surveys: 'Umfrage Übersicht',
      news: 'Meldungen verwalten (JSON)',
      dialNumbers: 'Nummern verwalten',
      blacklist: 'Teilnehmer sperren',
      pinBlacklist: 'PINs sperren',
      profile: 'Account & Sicherheit',
      supportCenter: 'Handbücher & Videos',
      serviceCenter: 'Brandings, Playbacks, Videoschnitt ...',
      presentation: 'Link zum visuellen Teil',
      businessCards: 'Für Referenten',
      liveVideoBackgrounds: 'Eigene Hintergründe'
    }
  },
  home: {
    plannedAppointment: 'Geplanter Termin',
    plannedAppointments: 'Geplante Termine',
    registeredUsers: 'Registrierte Teilnehmer',
    activeForm: 'Aktives Formular',
    activeForms: 'Aktive Formulare',
    createConference: 'Erstellen Sie eine neue Konferenz',
    newConference: 'Neue Konferenz',
    createRegForm: 'Erstellen Sie ein neues Anmeldeformular',
    newRegForm: 'Neues Anmeldeformular',
    anytimeCallable: 'Jederzeit anrufbar'
  },
  profile: {
    videoTutorials: 'Video Tutorials',
    handbooks: 'Handbücher',
    markAllRead: 'alle als gelesen markieren',
    markRead: 'als gelesen markieren',
    markUnread: 'als ungelesen markieren',
    notifications: 'Benachrichtigungen',
    accountAndSecurity: 'Account & Sicherheit',
    infoAndSecurity: 'Info & Sicherheit',
    customerNr: 'Kundennummer',
    deadline: 'Befristung',
    flatrate: 'Flatrate',
    changePassword: 'Passwort ändern',
    contactPartner: 'Ansprechpartner',
    confSetup: 'Konferenz-Setup',
    wizard: 'Wizard für Buchungen',
    doi: 'Double-Opt-In',
    callTries: 'Anwahl-Versuche',
    callDistance: 'Anwahl-Abstand',
    products: 'Produktfreigaben',
    personalData: 'Persönliche Daten',
    contactData: 'Kontaktdaten',
    account: 'Account',
    profileUpdated: 'Konto erfolgreich aktualisiert!',
    settings: {
      changeNavigationColor: 'Change Navigation Color:',
      anyColor: 'Any Color:',
      selectSpecificColor: 'Select Specific Color: (first color is default color)',
      changeHeaderColor: 'Change Header Color:',
      selectHeaderColorDefault: 'Select Header Color: (first color is default color)',
      selectTextColorDefault: 'Select Text Color: (first color is default color)',
      changePrimaryColor: 'Change Primary Color:',
      changeBackgroundColor: 'Change Background Color:',
      changeCardColor: 'Change Card Color:',
      changeNewsColor: 'Change News Color:',
      selectTextColor: 'Select Text Color:',
      changeFooterColor: 'Change Footer Color:',
      toggleBorderColon: 'Toggle Border:',
      toggleBorder: 'Toggle Border'
    }
  },
  adressbook: {
    adressbook: 'Adressbuch',
    adressbookFileName: 'Adressbuch',
    allContacts: 'Alle Kontakte',
    selectGroups: 'Adressbuchgruppen auswählen',
    selectedContacts: 'Ausgewählte Kontakte',
    addedSuccessfully: 'Teilnehmer mit Rufnummer erfolgreich ins Adressbuch übernommen',
    group: 'Gruppe',
    groups: 'Gruppen',
    pleaseSelectGroup: 'Bitte Gruppe auswählen',
    addContacts: 'Kontakte hinzufügen',
    contacts: 'Kontakte',
    addAsModerator: 'Als Moderator hinzufügen',
    asModerator: 'Als Moderator',
    addAsParticipant: 'Als Teilnehmer hinzufügen',
    asParticipant: 'Als Teilnehmer',
    noGroup: 'keine Gruppe',
    noContacts: 'Keine Kontakte',
    selectGroup: 'Gruppe auswählen',
    newGroup: 'Neue Gruppe',
    importContacts: 'Kontakte importieren',
    exportContacts: 'Kontakte exportieren',
    emptyAdressbook: 'Adressbuch leeren',
    import: {
      description1:
        'Sie können Kontakte als Excel- (XLSX) oder CSV-Datei hochladen und in Ihr Adressbuch importieren.',
      description2:
        'Die Datei muss je Kontakt mindestens den Namen und eine Rufnummer enthalten',
      description3:
        'Die Kontakte werden zu der ausgewählten Gruppe hinzugefügt',
      description4:
        'Ordnen Sie den Feldern eines Kontakts (links) die zu importierenden Spalten der XLSX-/CSV-Datei (rechts) zu:',
      dataChecked:
        'Ihre Daten wurden überprüft. Sie können den Import Ihrer Daten nun starten.',
      coding: 'Kodierung der XLSX-/CSV-Datei',
      validData: 'Gültige Datensätze',
      invalidData: 'Ungültige Datensätze',
      duplettes: 'Dubletten',
      deletedData: 'Gelöschte Datensätze',
      data: 'Datensätze',
      correctAndVerify:
        'Korrigieren oder Überprüfen Sie Ihre Datensätze und bestätigen Sie anschließend.',
      checkExcel:
        'Überprüfen Sie die Excel-Datei. Es darf nur ein Arbeitsblatt vorliegen.',
      dataImported: 'Daten erfolgreich importiert!',
      selectNameAndPhone: 'Bitte wählen Sie Name und Telefonnummer Felder aus'
    },
    groupSelection: 'Gruppenzuordnung',
    pullCsvHere: 'XLSX / CSV-Datei hier her ziehen',
    emailShown: 'E-Mail sichtbar',
    emailUnshown: 'E-Mail unsichtbar',
    companyShown: 'Firma sichtbar',
    companyUnshown: 'Firma unsichtbar',
    newContact: 'Neuen Kontakt anlegen',
    inGroup: 'in Gruppe "{group}"',
    addToGroup: 'In Gruppe hinzufügen',
    contactAdded: 'Kontakt wurde erfolgreich hinzugefügt!',
    contactDeleted: 'Kontakt wurde erfolgreich gelöscht!',
    contactsDeleted: 'Kontakte wurden erfolgreich gelöscht!',
    allContactsDeleted: 'Alle Kontakte wurden erfolgreich gelöscht!',
    allGroupsDeleted: 'Alle Gruppen wurden erfolgreich gelöscht!',
    groupExists: 'Gruppe existiert bereits',
    groupAdded: 'Gruppe wurde erfolgreich hinzugefügt',
    groupDeleted: 'Gruppe wurde erfolgreich gelöscht!',
    groupNameChanged: 'Gruppenname wurde erfolgreich verändert!',
    noContactsToExport: 'Keine Kontakte zum Exportieren vorhanden',
    excelFileError: 'Die Excel-Datei kann nicht importiert werden. Das kann verschiedene Gründe haben, z.B. ausgeblendete Spalten, mehrere Arbeitsblätter, Makros., ...\n' +
      'Bitte bereinigen Sie die Datei und speichern Sie das Dokument unter einem anderen Namen ab. Der erneute Speichervorgang behebt auch Fehler, falls die Datei beschädigt ist.'
  },
  audio: {
    add: 'Audiodatei hinzufügen',
    select: 'Audiodatei auswählen',
    orMoveHere: 'oder hierher ziehen',
    max: 'maximal 100 MB',
    files: 'Audiodateien',
    downloadPlayback: 'Playback herunterladen',
    uploaded: 'Audiodatei erfolgreich hochgeladen!',
    deleted: 'Audiodatei erfolgreich gelöscht!'
  },
  admin: {
    users: 'Benutzer',
    customers: 'Kunden',
    admins: 'Administratoren',
    manageUser: 'Benutzer verwalten',
    manageCustomer: 'Kunde verwalten',
    manageAdmins: 'Administratoren verwalten',
    editCustomers: 'Kunden editieren',
    editUser: 'Benutzer editieren',
    showConfDetails: 'Konferenzdetails anzeigen',
    registrationsCount: '{users} Teilnehmer, verteilt auf {dates} Termine',
    noCustomer: 'Keine Kunden',
    allCustomers: 'Alle Kunden',
    loginAsUser: 'Als {username} einloggen',
    login: 'Login',
    w2MatchingStatus: 'Matched',
    w2Matching: {
      noMatch: 'kein Match',
      matchWithPermission: 'Match mit Freigabe',
      matchWithoutPermission: 'Match ohne Freigabe'
    },
    customersCantLoad: 'Kunden konnten nicht geladen werden.',
    deleteAdminModal: {
      title: 'Administrator löschen',
      message: 'Möchten Sie den Administrator {name} ({username}) wirklich löschen?',
      deleted: 'Administrator erfolgreich gelöscht'
    },
    deleteCustomerModal: {
      title: 'Kunde löschen',
      message: 'Möchten Sie den Kunde "{title}" wirklich löschen?',
      deleted: 'Kunde erfolgreich gelöscht'
    },
    errorOnDeactivation: 'Fehler bei Deaktivierung',
    errorOnActivation: 'Fehler bei Aktivierung',
    editAdmin: 'Administrator editieren',
    lastLogin: 'letzter Login',
    deactivationReason: 'Grund: {reason}',
    permissions: 'Zugriffsrechte',
    customerManagement: 'Kundenverwaltung',
    userManagement: 'Benutzerverwaltung',
    loginAs: 'Login als',
    operatorConsole: 'Operatorkonsole',
    billing: 'Billing',
    conferenceHistory: 'Konferenzhistorie',
    systemConfiguration: 'Systemkonfiguration',
    numberManagement: 'Rufnummernverwaltung',
    saveDescription: 'Beschreibung speichern',
    userAccount: 'Benutzerkonto',
    failedLogins: 'fehlgeschlagener Login-Versuch | fehlgeschlagene Login-Versuche',
    passwordRequirements: 'Passwort muss mind. 8 Zeichen, Groß- & Kleinbuchstaben, mind. eine Ziffer und ein Sonderzeichen enthalten.',
    passwordRequirements2: 'Passwort muss mind. acht Zeichen, Zahlen, große Buchstaben und Sonderzeichen enthalten.',
    timeLimit: 'zeitliche Befristung',
    none: 'Keine',
    to: 'Bis',
    passwordPolicyChangeAfterLogin: 'Änderung nach ersten Login / Rückstellung',
    passwordPolicyChangeAfterLoginAndNDays: 'Änderung nach ersten Login / Rückstellung / alle {n} Tage',
    passwordPolicy: 'Passwort-Richtlinie',
    descriptionChangedSuccessfully: 'Beschreibung wurde erfolgreich geändert!',
    permissionChanged: 'Zugriffsrecht wurde erfolgreich geändert!',
    adminUpdated: 'Administrator erfolgreich aktualisiert!',
    passwordNotFollowingGuidelines: 'Passwort entspricht nicht den Richtlinien',
    usernameAlreadyTaken: 'Benutzername ist bereits vergeben',
    createAdmin: 'Administrator anlegen',
    notes: 'Bemerkungen',
    commentsForNotes: 'Kommentarfeld für Bemerkungen zum Administrator.',
    selectSalutation: 'Bitte wählen Sie eine Anrede',
    formContainsErrors: 'Das Formular enthält mind. einen Fehler. Bitte überprüfen Sie Ihre Angaben und korrigieren Sie die rot markierten Felder.',
    userAndDescriptionCreated: 'Benutzer erfolgreich hinzugefügt!',
    userDeleted: 'Benutzer erfolgreich gelöscht',
    usernameExists: 'Benutzername ist bereits vergeben.',
    exportAsXlsx: 'Export als xlsx',
    userLimitExceeded: 'Aktion nicht möglich. Nutzerlimit wurde erreicht.',
    userLimitExceededShort: 'Nutzerlimit erreicht',
    customerHasUsers: 'Aktion nicht möglich. Kunde hat Nutzer.',
    createUser: 'Nutzer anlegen',
    customerNumberShort: 'Kd.-Nr.',
    participantLimitShort: 'Flat',
    editCustomer: 'Kunde editieren',
    createdBy: 'angelegt von',
    active: 'aktiv',
    inactive: 'inaktiv',
    brandingsAfUf: 'Brandings (AF/UF)',
    showContaktDetailInAccount: 'Kontaktdaten in Benutzerkonto anzeigen',
    mustBeMail: 'Muss eine E-Mail sein.',
    invoiceRecipient: 'Rechnungsempfänger',
    billingCustomer: 'Rechnungskunde',
    testCustomer: 'Testkunde',
    likeContactPerson: 'wie Ansprechpartner',
    careOfShort: 'c/o',
    payment: 'Bezahlung',
    onBill: 'auf Rechnung',
    accountOwner: 'Kontoinhaber',
    iban: 'IBAN',
    ibanInvalid: 'Bitte geben Sie eine Valide IBAN',
    bic: 'BIC (Swift-Code)',
    instituteAuto: 'Institut (autom. Eintrag)',
    customerType: 'Kundentyp',
    individualConnectionProof: 'Einzelverbindungsnachweis',
    egn: 'EGN',
    userLimit: 'Benutzer-Limit',
    maxUsers: 'Max. {number} Benutzer',
    maxParticipants: 'Max. {number} Teilnehmer',
    participantLimit: 'Teilnehmer-Flatrate',
    weblinkToPresentation: 'Weblink zur Präsentation',
    numberShorted: 'Zielrufnummer um 3 Stellen gekürzt',
    unshorted: 'Ungekürzt',
    productChanged: 'Produktzugabe wurde erfolgreich geändert!',
    customerChanged: 'Kunde Erfolgreich aktualisiert!',
    createCustomer: 'Kunde anlegen',
    insertCompany: 'Firmennamen eingeben',
    addCustomer: 'Kunde hinzufügen',
    customerAndDescriptionAdded: 'Kunde und Beschreibung erfolgreich hinzugefügt!',
    customerAdded: 'Kunde erfolgreich hinzugefügt!',
    editDescription: 'Beschreibung bearbeiten',
    numbers: {
      manageNumbers: 'Rufnummern verwalten',
      bridgeNumber: 'Bridge Number',
      bridgeSetup: 'Bridge Setup',
      displayNumber: 'Display Number',
      displayTitle: 'Display Bezeichnung',
      display: 'Anzeige',
      lindenbaumDescription: 'Lindenbaum-Beschreibung',
      insertNewDisplayNumber: 'Neue Displaynummer von {number} eingeben',
      insertNewDescription: 'Neue Beschreibung von {number} eingeben',
      newDisplayNumber: 'Neue Displaynummer',
      newDescription: 'Neue Beschreibung'
    },
    operator: {
      nOperatorServices: '{number} Operator-Services',
      plannedPastConferences: '{planned} geplante / {passed} vergangene Konferenzen',
      loginAsUser: 'Login als Nutzer',
      assignOperator: 'Operator zuweisen',
      contactOrganizer: 'Organisator kontaktieren',
      cancelBooking: 'Buchung stornieren'
    },
    operatorServices: 'Operator Services',
    noUsers: 'Keine Benutzer',
    cantLoad: 'Konten konnten nicht geladen werden.',
    customerhasNoProducts: 'Kunde hat keine Produkte',
    createDelete: 'anlegen/löschen',
    changeFlat: 'Flatrate ändern',
    changeBillingDetails: 'Rechnungsdaten ändern',
    changeContactPartner: 'Ansprechpartner ändern',
    userStats: 'Nutzungsstatistik',
    mediaLibrary: 'Mediathek',
    globalBrandings: 'Globale Brandings',
    globalInputFields: 'Globale Eingabefelder',
    createUserLong: 'Benutzer anlegen',
    addUser: 'Benutzer hinzufügen',
    plsSelectCustomer: 'Bitte wählen Sie einen Kunde aus',
    insertUsername: 'Benutzername eingeben',
    notesForUser: 'Kommentarfeld für Bemerkungen zum Benutzer',
    customerDefault: 'Vorbelegung Kunde',
    selectCustomer: 'Kunde auswählen',
    nDaysPreview: '{n} Tage Vorschau',
    perDay: '/Tag',
    systemload: 'Systemload',
    excel: {
      dbId: 'DB ID',
      dbCustomerId: 'DB Kunden-ID',
      participantLimit: 'Teilnehmer-Limit',
      dialOutRetryCount: 'Wahlwiederholung',
      dialOutRetryDelay: 'Wahlabstand',
      blacklistEmail: 'Blacklist E-Mail',
      blacklistPhone: 'Blacklist Telefon',
      passwordPolicy: 'Passwort Policy',
      role: 'Rolle',
      accountSettings: 'Account-Einstellungen',
      deactivationReason: 'Deaktivierungsgrund',
      failedLogins: 'gescheiterte Logins',
      lindenbaumId: 'Lindenbaum-Id',
      lindenbaumUsername: 'Lindenbaum-Benutzername',
      lindenbaumLastSync: 'letzte Synchronisierung Lindenbaum',
      regFormShort: 'AF',
      surveyFormShort: 'UF',
      bankName: 'Bankname',
      egnShorted: 'EGN gekürzt',
      showContactDetailInAccount: 'Kontaktdaten in Accounts zeigen',
      presentationUrl: 'Datenkonferenz URL',
      deleted: 'gelöscht',
      asp: 'ASP',
      rg: 'RG'
    },
    invalidUrl: 'Keine valide URL.',
    invalidUrlLogin: 'Die URL darf nicht mit \'login\' enden.',
    surveys: {
      headerSubtitle: '{totalRows} aktive Formulare mit {participants} Teilnehmern',
      noSurveys: 'Keine Umfragen',
      activeForms: 'Aktive Formulare',
      allForms: 'Alle Formulare',
      lastParticipantShort: 'letzter Teiln.',
      questionsShort: 'Frg.',
      surveysCantLoad: 'Umfragen konnten nicht geladen werden',
      noCert: 'Kein Zertifikat vorhanden',
      certUploaded: 'Zertifikat hochgeladen',
      certSent: 'Zertifikat verschickt',
      evaluationActive: 'Bewertung aktiviert',
      noEvaluation: 'Keine Bewertung aktiviert'
    },
    userExcelExport: 'Excel-Export der Benutzer',
    customerExcelExport: 'Excel-Export der Kunden'
  },
  conference: {
    conferences: 'Konferenzen',
    runningConferences: 'Laufende Konferenzen',
    runningConference: 'Laufende Konferenz',
    countRunningConferences: '{count} Laufende Konferenzen | 1 Laufende Konferenz | {count} Laufende Konferenzen',
    OneRunningConference: 'Laufende Konferenz',
    scheduledConferences: 'Geplante Konferenzen',
    scheduledConference: 'Geplante Konferenz',
    countScheduledConferences: '{count} Geplante Konferenzen | 1 Geplante Konferenz | {count} Geplante Konferenzen',
    pastConferences: 'Vergangene Konferenzen',
    pastConference: 'Vergangene Konferenz',
    countPastConferences: '{count} Vergangene Konferenzen | 1 Vergangene Konferenz | {count} Vergangene Konferenzen',
    newConference: 'Neue Konferenz',
    editConference: 'Konferenz bearbeiten',
    bookConference: 'Konferenz buchen',
    bookNow: 'Jetzt buchen',
    roomConferences: 'Jederzeit Konferenzräume',
    newConferenceRoom: 'Neuer Konferenzraum',
    costCenter: 'Kostenstelle',
    costCenterShort: 'Kst',
    noCostCenter: 'keine Kostenstelle',
    chooseCostCenter: 'Kostenstelle auswählen',
    newCostCenter: 'Neue Kostenstelle',
    seriesDate: 'Serientermin',
    series: 'Serie',
    playback: 'Playback',
    live: 'Live',
    liveVideo: 'Live Video',
    showOperatorBooking: 'Operatorbuchung anzeigen',
    editOperatorBooking: 'Operatorbuchung bearbeiten',
    noOperatorBooked: 'keine Operator gebucht',
    bookOperator: 'Operator-Service buchen',
    operator: 'Operator',
    operatorShort: 'OP',
    moderatorPin: 'Moderation PIN',
    moderatorPinShort: 'PIN-Mod',
    participantPin: 'Teilnehmer PIN',
    participantPinShort: 'PIN-Tln',
    pinShort: 'PIN (Mod/Tln)',
    pinShort2: 'MP/TP',
    pinLong: 'PIN (Moderator - Teilnehmer)',
    pin: 'PIN',
    moderator: 'Moderator',
    moderatorShort: 'Mod',
    participant: 'Teilnehmer',
    participantShort: 'Tln',
    registeredUsersShort: 'TN',
    unlimited: 'unbegrenzt',
    conferenceOptions: 'Konferenz-Einstellungen',
    audioOptions: 'Audio-Einstellungen',
    options: {
      schooling: 'Schulungsmodus (Teilnehmer stumm)',
      schoolingDescription: 'Wortmeldungen sind per Telefon *1 möglich',
      waitingRoom: 'Warteraum für Dial-In und Dial-Out Teilnehmer',
      waitingRoomDescription: 'Ermöglicht eine Vorbesprechung der Referenten',
      endOnModHangup: 'Konferenz beenden, wenn Moderator auflegt',
      endOnModHangupDescription: 'Empfohlen wird die Nutzung der Websteuerung',
      autoPlayback: 'Gesprächsmitschnitt automatisch starten',
      autoPlaybackDescription:
        'Teilnehmer ggfs. über den Mitschnitt informieren',
      beepOnAdd: 'Signalton bei Teilnehmerzuschaltung',
      beepOnAddDescription: 'Nur in kleinen Konferenzen empfehlenswert',
      beepOnEnd: 'Signalton, wenn Teilnehmer auflegt',
      activeConfirmation: 'Aktive Bestätigung bei Anruf',
      activeConfirmationDescription:
        'Verhindert die Aufnahme per Anrufbeantworter',
      functionNotAvailable: 'Funktion vorübergehend nicht verfügbar',
      notAvailableInPlayback: 'Bei Playback-Konferenzen nicht möglich'
    },
    noRegForm: 'kein Anmeldeformular',
    noSurveyForm: 'kein Umfrageformular',
    assignForm: 'zuordnen',
    assignRegForm: 'Anmeldeformular zuordnen',
    assignSurveyForm: 'Umfrageformular zuordnen',
    selectedConference: 'Ausgewählte Konferenz',

    endConference: 'Konferenz beenden',
    deleteConference: 'Konferenz löschen',

    controlPanel: 'Steuerung',
    openControlPanel: 'Steuerung öffnen',
    showConfirmation: 'Buchungsbestätigung anzeigen',
    confirmationAndServices: 'Buchungsbestätigung & Services',
    startImmediately: 'Sofort starten',
    dialNumbers: 'Einwahlnummern',
    dialNumbersTitle: 'Nationale Einwahl- und Servicerufnummern',
    useAsTemplate: 'Als Vorlage nutzen',
    conferenceDetails: 'Konferenzdetails',
    downloadRecording: 'Mitschnitt downloaden',

    chooseCountry: 'Land auswählen',
    playbacks: 'Playbacks',
    noPastConference: 'keine vergangenen Konferenzen',
    noRunningConference: 'keine laufende Konferenzen',
    noScheduledConference: 'keine geplanten Konferenzen',
    liveConference: 'Live-Konferenz',
    recording: 'Recording',
    rec: 'Rec',
    dialout: 'Dial Out',
    dialin: 'Dial In',
    conferenceEnded: 'Konferenz beendet',
    conferenceEndConfirm: 'Möchten Sie diese Konferenz wirklich beenden?',
    functions: 'Funktionen',
    bookedFunctions: 'gebuchte Funktionen',
    conferenceStarted: 'Konferenz erfolgreich gestartet!',
    conferenceDeleted: 'Konferenz erfolgreich gelöscht!',
    seriesDeleted: 'Konferenzserie erfolgreich gelöscht!',
    bookingConfirmation: 'Buchungsbestätigung',

    mailToParticipants: 'Mail an Teilnehmer',
    downloadConfirmation: 'Bestätigung downloaden',
    downloadDetails: 'Konferenzdetails downloaden',
    downloadExpandedDetails: 'Erweiterte Konferenzdetails downloaden',
    noConference: 'Keine Konferenz vorhanden',

    event: 'Veranstaltung',
    eventType: 'Veranstaltungsart',
    settings: 'Konferenz- und Audio-Einstellungen',
    dialInConference: 'Einwahl in die Konferenz',
    moreDialNumbers: 'Weitere Rufnummern',
    sections: {
      languageInEmails: 'Sprache in den E-Mails und Ansagen',
      eventType: 'Art der Veranstaltung',
      mode: 'Modus',
      costCenter: 'Kostenstelle',
      waitingRoom: 'Warteraum',
      conferenceEnd: 'Konferenzende',
      recording: 'Gesprächsmitschnitt',
      tone: 'Signalton',
      confirmation: 'Bestätigung bei Anruf',
      modPin: 'Moderator-PIN',
      partPin: 'Teilnehmer-PIN',
      pin: 'Moderator-PIN / Teilnehmer-PIN',
      regForm: 'Anmeldeformular',
      surveyForm: 'UmfrageFormular',
      dialNumbers: 'Einwahlrufnummern',
      language: 'Sprache'
    },
    sectionValues: {
      schooling: 'Schulung (Teilnehmer stumm)',
      talk: 'Besprechung',
      activated: 'Aktiviert',
      deactivated: 'Deaktiviert',
      whenModEnds: 'Wenn Moderator auflegt',
      manual: 'Manuell',
      automatic: 'Automatisch'
    },
    anytime: 'Jederzeit',
    immediate: 'Sofort',
    onetime: 'Einmalig',
    repeated: 'wird wiederholt',
    nameOfRoom: 'Name des Raums',

    noParticipants: 'Keine Teilnehmer',
    noParticipantsAvailable: 'Keine Teilnehmer vorhanden',
    noParticipantsFiltered: 'Kein Teilnehmer entspricht den Suchkriterien.',
    loadingParticipants: 'Teilnehmer werden geladen',
    repeatConference: 'Konferenz wiederholen',
    recordingManuallyDeleted: 'Aufzeichnung manuell gelöscht',
    noRecording: 'Keine Aufzeichnung vorhanden',
    deleteSeries: 'Konferenzserie löschen',
    topicPlaceholder: 'Thema eingeben... (z.B. Marketing-Jour Fix)',
    topicPlaceholder2: 'Geben Sie ein Thema ein...',
    languageDescription: 'Sprache der E-Mail und Ansagen',
    playbackOrLive: 'Planen Sie eine Live-Konferenz oder ein Playback?',

    selectPlayback: 'Playback Auswählen',
    selectedPlayback: 'ausgewähltes Playback',
    noPlaybackSelected: 'kein Playback ausgewählt',
    immediateDescription:
      'Die Konferenz startet unmittelbar nach dem Buchungsvorgang',
    startDate: 'Startdatum',
    time: 'Zeit',
    oneDate: '1 Termin',
    dates: 'Termine',
    maxHundredDates: 'maximal 100 Termine',
    startDateAfterEnd: 'Startdatum kommt nach dem Enddatum',
    daily: 'täglich',
    weekly: 'wöchentlich',
    everyTwoWeeks: 'alle zwei Wochen',
    everyThreeWeeks: 'alle drei Wochen',
    everyVierWeeks: 'alle vier Wochen',
    everyNWeeks: 'alle {n} Wochen',
    monthly: 'monatlich',
    everyFirst: 'jeden 1.',
    everySecond: 'jeden 2.',
    everyThird: 'jeden 3.',
    everyFourth: 'jeden 4.',
    everyLast: 'jeden letzten',
    dialInDescription:
      'Zur Einwahl in die Konferenz können Teilnehmer eine der nachstehenden Rufnummern anrufen.',
    welcomingAndSecurity: 'Begrüßung und Sicherheit',
    welcomingDescription:
      'Nach der Einwahl werden die Teilnehmer automatisch begrüßt und zur Eingabe eines 6-stelligen Zugangscodes aufgefordert.',
    pinForMod: 'Zugangscode für Moderatoren',
    pinForPart: 'Zugangscode für Teilnehmer',
    enterPin: '6 stellige PIN eingeben',
    pinErrors: {
      sixDigits: 'Bitte geben Sie 6 Ziffern ein',
      different: 'Pins müssen unterschiedlich sein',
      exists: 'Zugangscode ist zu diesem Zeitpunkt nicht möglich',
      modCodeExists: 'Moderator-Zugangscode ist zu diesem Zeitpunkt nicht möglich',
      partCodeExists: 'Teilnehmer-Zugangscode ist zu diesem Zeitpunkt nicht möglich'
    },
    continueEditing: 'Konferenz weiter bearbeiten',
    pleaseFixError: 'Bitte beheben Sie die Fehler',
    pleaseClickOutside:
      'Bitte klicken Sie außerhalb der Teilnehmertabelle um alle Felder zu validieren',
    pleaseSelectPlayback: 'Bitte wählen Sie einen Playback aus',
    startMinimumTenSeconds:
      'Die Startzeit der Konferenz muss mindestens 10 Sekunden in der Zukunft liegen.',
    participantsMinimum: 'Bitte geben Sie mindestens einen Teilnehmer ein',
    moderatorMinimum: 'Bitte geben Sie mindestens einen Moderator ein',
    chooseFromAdressbook: 'Auswahl aus Adressbuch',
    namePlaceholder: 'Name eingeben',
    numberPlaceholder: 'Bsp. +49 123 4567890',
    inputEmail: 'E-Mail eingeben',
    inputCompany: 'Firma eingeben',
    participantDeleted: 'Teilnehmer wurde erfolgreich gelöscht!',
    participantAdded: 'Teilnehmer wurde erfolgreich hinzugefügt!',
    addRoom: 'Konferenzraum anlegen',
    bookRoom: 'Konferenzraum buchen',
    room: 'Konferenzraum',
    roomDescription:
      'Nach der Einrichtung steht Ihnen der Konferenzraum sofort zur Verfügung. Sie können ihn jederzeit nutzen.',
    backTo: 'Zurück zu {name}',
    continueTo: 'Weiter zu {name}',
    noChangePossible: 'Eine Bearbeitung oder Löschung ist unmittelbar vor Konferenzstart nicht möglich.',
    selectDialInOrCallParticipants: 'Bitte schalten Sie mindestens "Einwahl" oder "Teilnehmer anrufen" ein.',
    editOrDeleteIstNotPossibleBeforeStart: 'Eine Bearbeitung oder Löschung ist unmittelbar vor Konferenzstart nicht möglich.',
    numberConfirmedTotalParticipants: 'Anzahl bestätigter / insgesamt registrierter Teilnehmer',
    showMore: 'weitere anzeigen',
    collaboration: 'Kollaboration',
    audioStreaming: 'Audio Streaming',
    unknown: 'unbekannt',
    busy: 'besetzt',
    notReached: 'nicht erreicht',
    ended: 'beendet',
    extraFieldAFI: 'Zusatzfeld AF {i}',
    checkboxAFI: 'Checkbox AF {i}',
    extraFieldUFI: 'Zusatzfeld UF {i}',
    checkboxUFI: 'Checkbox UF {i}',
    customFieldAFI: 'eigenes Feld AF {i}',
    customFieldUFI: 'eigenes Feld UF {i}',
    registrationTimeAF: 'Registrierungszeit AF',
    registrationTimeUF: 'Registrierungszeit UF',
    certificateSentAF: 'Zertifikat verschickt (AF)',
    certificateSentUF: 'Zertifikat verschickt (UF)',
    endDate: 'Enddatum',
    mailDuplicatesDetected: 'Eine E-Mail-Adresse oder Rufnummer ist doppelt in der Teilnehmerliste',
    onlyParticipantsWithNumberAreCalled: 'Bitte beachten Sie, dass nur die Teilnehmer angerufen werden, die ihre Rufnummer bei der Registrierung angegeben haben. Alle anderen Teilnehmer müssen sich selbst einwählen.',
    dialoutButNoPhoneRequired: 'Einer Dial-Out Konferenz können nur Anmeldeformulare zugeordnet werden, in denen die Rufnummer als Pflichtfeld abgefragt wird.',
    dialInDeactivatedWarning: 'Dieser Konferenz ist ein Anmeldeformular zugeordnet, welches die Telefonnummer nicht verpflichtend abfragt. Daher muss die Dial-In Option aktiviert bleiben.'
  },
  modals: {
    deleteBooking: {
      title: 'Konferenz oder Konferenzserie löschen',
      description:
        'Diese Konferenz gehört zu einer Serie. Möchten Sie die ganze Konferenzserie löschen oder nur diese Konferenz?',
      deleteSeries: 'Serie löschen',
      deleteConferenceOnly: 'nur Konferenz löschen'
    },
    deleteScheduled: {
      title: 'Geplante Konferenz löschen',
      description: 'Möchten Sie die geplante Konferenz wirklich löschen?'
    },
    mailToParticipants: {
      title: 'E-Mail an Teilnehmer senden',
      description:
        'An {count} Teilnehmer der Konferenz <b>{title}</b> eine E-Mail senden.'
    },
    deleteRecording: {
      title: 'Datei löschen',
      description: 'Möchten Sie die Aufzeichnung löschen?',
      confirm: 'Aufzeichnung löschen',
      confirmed: 'Aufzeichnung erfolgreich gelöscht!'
    },
    deleteConference: {
      title: 'Konferenz löschen',
      description: 'Möchten Sie die Konferenz wirklich löschen?',
      confirmed: 'Konferenz erfolgreich gelöscht!'
    },
    deleteSeries: {
      title: 'Konferenzserie löschen',
      description: 'Möchten Sie die Konferenzserie wirklich löschen?',
      confirmed: 'Konferenzserie erfolgreich gelöscht!'
    },
    seriesChangesForms:
      'Bitte beachten Sie, dass die Änderung eines Serientermins eine erneute Zuordnung von Anmelde- und Umfrageformularen erfordert.',
    wizardError: {
      description:
        'Dieser Wizard-Schritt enthält einen Fehler oder ungespeicherte Änderungen. Möchten Sie Ihre Änderungen verwerfen, und zum nächsten Schritt gehen, oder hier bleiben?',
      title: 'Ungespeicherte oder fehlerhafte Änderungen',
      ok: 'Verwerfen und Weiter',
      cancel: 'Hier bleiben'
    },
    deleteGroup: {
      title: 'Gruppe löschen',
      description:
        'Möchten Sie die Gruppe, die Gruppe mit Kontakten oder nur die enthaltenen Kontakte löschen?',
      withContacts: 'Gruppe mit Kontakten löschen',
      onlyContacts: 'Nur Kontakte löschen',
      onlyGroup: 'Nur Gruppe löschen',
      otherOptions: 'Andere Möglichkeiten'
    },
    emptyAdressbook: {
      title: 'Adressbuch leeren',
      description:
        'Möchten Sie alle Kontakte löschen? Oder alle Kontakte die keiner Gruppe zugeordnet sind?',
      contactsAndGroups: 'Kontakte und Gruppen löschen',
      allContactsAndGroups: 'Alle Kontakte und Gruppen löschen',
      otherOptions: 'Andere Möglichkeiten',
      withoutGroup: 'Alle Kontakte löschen, die keiner Gruppe zugeordnet sind'
    },
    deleteSelectedContacts: {
      title: 'Ausgewählte Kontakte löschen',
      description: 'Möchten Sie die ausgewählten Kontakte löschen?',
      ok: 'Ausgewählte Kontakte löschen'
    },
    deleteContact: {
      title: 'Kontakt löschen',
      description: 'Möchten Sie den Kontakt von "{name}" löschen?',
      ok: 'Kontakt löschen'
    },
    operatorConference: {
      title: 'Konferenz zuordnen',
      description:
        'Durch die Zuordnung einer Konferenz werden die Referenten, der Termin und Einstellungen automatisch übernommen.'
    },
    operatorRegForm: {
      title: 'Anmeldeformular zuordnen',
      description:
        'Durch die Zuordnung eines Anmeldeformulars werden der Termin und weitere Einstellungen automatisch übernommen.',
      selectDate: 'Wählen Sie einen Termin aus.',
      selectDateShort: 'Termin auswählen...'
    },
    operatorSurveyForm: {
      title: 'Umfrageofrmular zuordnen',
      description:
        'Durch die Zuordnung eines Umfrageformulars kann der Operator den Konferenzumfang besser einschätzen.',
      selectDate: 'Wählen Sie einen Termin aus.',
      selectDateShort: 'Termin auswählen...'
    },
    deleteSeriesOrConference: {
      title: 'Konferenz oder Konferenzserie löschen',
      description:
        'Diese Konferenz gehört zu einer Serie. Möchten Sie die ganze Konferenzserie löschen oder nur diese Konferenz?',
      deleteSeries: 'Serie löschen',
      onlyConference: 'nur Konferenz löschen'
    },
    brandingPrivacyModal: {
      title: 'Datenschutzerklärung',
      ok: 'Schließen',
      description: 'Hier können Sie die Datenschutzerklärung ändern',
      privacyPreview: 'Datenschutzerklärung-Vorschau',
      privacyText: 'Hinweise zur Verarbeitung Ihrer personenbezogenen Daten erhalten Sie in der <span class="text-primary">Datenschutzerklärung</span>.',
      privacyHint:
        'Datenschutzerklärungslink wird in den Formular(en) festgelegt'
    },
    brandingFooterModal: {
      title: 'Footer',
      ok: 'Schließen',
      footerActive: 'Footer aktiviert',
      description: 'Geben Sie hier einen neuen Footer ein',
      footerPreview: 'Footer-Vorschau',
      footerText:
        'Ihre mit der Anmeldung übersandten Daten werden durch {company} und den Dienstleister CSN. Communication Service Network GmbH zur Durchführung des Webinars verarbeitet.',
      noFooter: 'Kein Footer'
    },
    regInfoModal: {
      title: 'Anmeldeformular teilen',
      ok: 'Schließen',
      description1:
        'Sie können den Link zum Formular kopieren und Ihren Teilnehmern per E-Mail zukommen lassen. Alternativ können Sie ihn auf Ihrer Homepage einbinden oder in sozialen Netzwerken promoten.',
      weblink: 'Ihr Weblink zum Anmeldeformular lautet',
      idParam: 'Regionalvertrieb',
      weblinkExample:
        'Beispiel eines Weblinks mit Vorbelegung einer Partner-ID',
      note: 'Für internet-versierte Nutzer besteht die Möglichkeit, die Standard- und Zusatzfelder des Anmeldeformulars über URL-Parameter vorzubelegen. So können Sie z.B. Ihren Vertriebspartnern individuelle Anmeldelinks zur Verfügung stellen, in denen die Partner-ID eincodiert ist. Wenn sich ein Teilnehmer über so einen Link registriert, dann kann er später einem Partner zugeordnet werden.',
      footer:
        'Als URL-Parameter können die Standardfelder (firstname, lastname, email, phone, company, comment, id oder partnerId, addressStreet, addressCity, vvId, salutation) und auch die Zusatzfelder genutzt werden.'
    },
    surveyInfoModal: {
      title: 'Umfrageformular teilen',
      ok: 'Schließen',
      description1:
        'Sie können den Link zum Formular kopieren und Ihren Teilnehmern per E-Mail zukommen lassen. Alternativ können Sie ihn auf Ihrer Homepage einbinden oder in sozialen Netzwerken promoten.',
      weblink: 'Ihr Weblink zum Umfrageformular lautet',
      weblinkExample:
        'Beispiel eines Weblinks mit Vorbelegung einer Partner-ID',
      note: 'Für internet-versierte Nutzer besteht die Möglichkeit, die Standard- und Zusatzfelder des Anmeldeformulars über URL-Parameter vorzubelegen. So können Sie z.B. Ihren Vertriebspartnern individuelle Anmeldelinks zur Verfügung stellen, in denen die Partner-ID eincodiert ist. Wenn sich ein Teilnehmer über so einen Link teilnimmt, dann kann er später einem Partner zugeordnet werden.',
      footer:
        'Als URL-Parameter können die Standardfelder (firstname, lastname, email, phone, company, comment, id oder partnerId, addressStreet, addressCity, vvId, salutation) genutzt werden.'
    },
    deleteFormModal: {
      title: 'Formular löschen',
      description: 'Möchten Sie das Formular wirklich löschen?',
      ok: 'Formular löschen',
      deleted: 'Formular erfolgreich gelöscht!'
    },
    deleteParticipantsModal: {
      title: 'Teilnehmer löschen',
      description: 'Möchten Sie wirklich alle Teilnehmer löschen?',
      ok: 'Alle Teilnehmer löschen',
      deleted: 'Formularteilnehmer erfolgreich gelöscht!'
    },
    deleteOldParticipantsModal: {
      title: 'Alle alte Teilnehmer löschen',
      ok: 'Alle alte Teilnehmer löschen',
      description: 'Möchten Sie wirklich Alle alten Teilnehmer löschen',
      deleted: 'Alle alten Teilnehmer erfolgreich gelöscht.'
    },
    deleteOldRegDatesAndParticipantsModal: {
      title: 'Alle alte Termine & Teilnehmer löschen',
      ok: 'Alle alte Termine & Teilnehmer löschen',
      description:
        'Möchten Sie wirklich alle alte Termine & Teilnehmer löschen?',
      deleted: 'Alle alte Termine & Teilnehmer erfolgreich gelöscht.'
    },
    deleteAllOldRegModal: {
      title: 'Alle alten Formulare löschen',
      ok: 'Alle alten Formulare vollständig löschen',
      description:
        'Möchten Sie wirklich alle alten Formulare vollständig löschen? Es ist dann keine Auswertung der dazugehörigen Veranstaltung mehr möglich.',
      deleted: 'Alle alten Formulare vollständig gelöscht.'
    },
    deleteDateParticipants: {
      title: 'Alle Teilnehmer löschen',
      ok: 'Teilnehmer löschen',
      description:
        'Möchten Sie alle registrierten Teilnehmer des ausgewählten Termins wirklich löschen?',
      deleted: 'Teilnehmer  wurden gelöscht!'
    },
    deleteAllDatesParticipants: {
      title: 'Alle Teilnehmer löschen',
      ok: 'Teilnehmer löschen',
      description:
        'Möchten Sie alle registrierten Teilnehmer des Formulars wirklich löschen?',
      deleted: 'Alle Teilnehmer wurden gelöscht!'
    },
    deleteDateParticipantsLong: {
      title: 'Teilnehmer löschen',
      ok: '{count} Teilnehmer löschen',
      description:
        'Möchten Sie die Teilnehmer des Termins \'{title}\' löschen? Bei einem historischen Termin werden dadurch die erweiterten Konferenzdetails gelöscht.',
      deleted: 'Teilnehmer wurden erfolgreich gelöscht'
    },
    sendFormMail: {
      title: 'E-Mail an Teilnehmer verschicken',
      regDescription:
        'an <b>{count}</b> Teilnehmer des Termins <b>{date}</b> eine E-Mail schicken',
      replyEmail: 'Antwort E-Mail'
    },
    deleteParticipant: {
      title: 'Teilnehmer löschen',
      description: 'Möchten Sie den Teilnehmer {name} wirklich löschen?',
      ok: 'Teilnehmer löschen',
      deleted: 'Teilnehmer erfolgreich gelöscht'
    },
    deleteParticipants: {
      title: 'Teilnehmer löschen',
      description: 'Möchten Sie {count} Teilnehmer wirklich löschen?',
      ok: 'Teilnehmer löschen',
      deleted: 'Teilnehmer erfolgreich gelöscht'
    },
    deleteSurvey: {
      title: 'Formular löschen',
      descWithParticipants:
        'In dem ausgewählten Formular sind Teilnehmer registriert. Soll es trotzdem gelöscht werden?',
      descNoParticipants: 'Möchten Sie dieses Formular wirklich löschen?',
      ok: 'Formular löschen',
      deleted: 'Formular erfolgreich gelöscht!'
    },
    deleteSurveyResponses: {
      title: 'Teilnehmer löschen',
      description:
        'Möchten Sie alle Teilnehmer des Umfrageformulars wirklich löschen?',
      ok: 'Teilnehmer löschen',
      deleted: 'Teilnehmer erfolgreich gelöscht!',
      noResponses: 'Das Umfrageformular hat keine Teilnehmer.'
    },
    certificateSetup: {
      title: 'Zertifikat-Setup'
    },
    importParticipants: {
      title: 'Teilnehmer Importieren',
      requiredFields: 'Bitte ordnen Sie die Pflichtfelder Nachname, Telefonnummer und E-Mail zu',
      encodingXlsxCsv: 'Kodierung der XLSX-/CSV-Datei:',
      pullCsvHere: 'XLSX / CSV-Datei hier her ziehen',
      validDataset: 'gültige Datensätze',
      validDatasetCap: 'Gültige Datensätze',
      invalidDatasets: 'ungültige Datensätze',
      deletedDatasets: 'gelöschte Datensätze',
      editInvalidDatasets: 'Ungültige Datensätze bearbeiten:',
      editDatasetDescription: 'Korrigieren oder Überprüfen Sie Ihre Datensätze und bestätigen Sie anschließend.',
      participantsExistsDescription: 'Diese Teilnehmer existieren bereits im Formular als Teilnehmer oder in den gültigen oder ungültigen Datensätzen mit identischer E-Mail-Adresse oder Telefonnummer.'
    },
    gutBeratenSetup: {
      title: '"gut beraten"- Setup',
      csvRequired: 'Zur Meldung von Teilnehmern, die an einer Bildungsmaßnahme von "gut beraten" teilgenommen haben, wird eine CSV-Datei in einem speziellen Format benötigt.',
      minimumConnectionTime: 'Mindest-Verbindungsdauer',
      educationBonus: 'Bildungszeit-Gutschrift',
      learningTypes: {
        face2face: 'Präsenzveranstaltung',
        individual: 'Einzeltraining',
        blended: 'Blended Learning',
        controlled: 'gesteuertes E-Learning',
        selfControlled: 'selbstgesteuertes E-Learning',
        workplace: 'Lernen am Arbeitsplatz'
      },
      learningContents: {
        privatePreventionLifePensionInsurance: 'Privat-Vorsorge-Lebens-/Rentenversicherung',
        privatePreventionHealthCareInsurance: 'Privat-Vorsorge-Kranken-/Pflegeversicherung',
        privatePropertyDamageInsurance: 'Privat-Sach-/Schadenversicherung',
        corporateCustomerPrevention: 'Firmenkunden-Vorsorge (bAV/Personenversicherung)',
        corporateCustomerPropertyDamageInsurance: 'Firmenkunden-Sach-/Schadenversicherung',
        crossDivisional: 'Spartenübergreifend',
        consultingSkills: 'Beratungskompetenz'
      }
    },
    placeholder: {
      placeholderFor: 'Platzhalter für {title}',
      regTitle: 'Titel des Anmeldeformulars',
      regSubtitle: 'Untertitel des Anmeldeformulars',
      regDescription: 'Beschreibungstext des Anmeldeformulars',
      salutation: 'Sehr geehrter Herr, Sehr geehrte Frau, Sehr geehrte*r',
      numberWithCountryCode: 'Rufnummer mit Ländervorwahl',
      email: 'E-Mail-Adresse',
      regDateTime: 'Datum der Anmeldung (DD. Monat YYYY, HH:MM Uhr)',
      regPin: 'PIN der Veranstaltung',
      regDialInNumber: 'Einwahlnummern in die Konferenz (DE/EN)',
      regDialInInternational: 'Einwahlnummern in die Konferenz (International)',
      owner: 'Inhaber des Accounts (siehe Profil)',
      regDoiLink: 'Link zum Bestätigen von Double-Opt-In',
      regPresentationLink: 'Link zur Präsentation ({url}). Name & PIN sind voreingetragen',
      regLoginButton: 'Personalisierter Login-Button zur Präsentation ({url}). Name, PIN und alle anderen Eingaben werden im Hintergrund mit übergeben.',
      surveyTitle: 'Titel des Umfrageformulars',
      surveySubtitle: 'Untertitel des Umfrageformulars',
      surveyDescription: 'Beschreibungstext des Umfrageformulars',
      surveyDateTime: 'Datum der Teilnahme (DD. Monat YYYY, HH:MM Uhr)',
      surveyDate: 'Datum der Teilnahme (DD.MM.YYYY)',
      surveyPassed: 'Dieser Text erscheint bei einer bestandenen Prüfung.',
      surveyFailed: 'Dieser Text erscheint bei einer nicht bestandenen Prüfung.',
      surveyReachedPoints: 'Erreichte Punktzahl',
      surveyTotalPoints: 'Gesamtpunktzahl',
      surveyPercent: 'Erreichte prozentuale Punktzahl (z.B. 50%)',
      connectionTime: 'Verbindungszeit, abgerundet auf volle Minuten'
    },
    deleteField: {
      title: 'Eingabefeld löschen',
      description: 'Möchten Sie wirklich dieses Eingabefeld löschen?',
      ok: 'Eingabefeld löschen'
    },
    deleteAccount: {
      title: 'Benutzer löschen',
      description: 'Möchten Sie wirklich den Benutzer {name} löschen?',
      ok: 'Benutzer löschen'
    }
  },
  forms: {
    components: {
      doi: {
        name: 'Double-Opt-In',
        legalHint: 'Wenn ein Formular für die Einladung zu einer Werbeveranstaltung oder die Konferenzanmeldung mit einer Newsletter-Anmeldung verknüpft wird, dann ist laut der Datenschutzgrundverordnung (DSGVO) und dem Gesetz gegen den unlauteren Wettbewerb (UWG) das Double-Opt-In Verfahren einzusetzen. Der Interessent erhält in diesem Fall eine E-Mail mit einem Bestätigungslink zugeschickt, über den er seine Anmeldung aktiv bestätigen muss.',
        conferenceOption: 'Double-Opt-In zur Registrierung für eine Werbeveranstaltung',
        conferenceHint: 'Für die Teilnahme an der Veranstaltung ist eine aktive Bestätigung der Registrierung erforderlich.',
        newsletterOption: 'Double-Opt-In zur Einwilligung bzgl. der werblichen Datenverarbeitung (z.B. Newsletter)',
        newsletterHint: 'Die aktive Bestätigung bezieht sich in diesem Fall nicht auf die Teilnahme an der Veranstaltung.',
        newsletterCheckboxLabel: 'Checkbox-Text*',
        newsletterCheckboxDefault: 'Hiermit willige ich ein, dass meine E-Mail-Adresse für den Versand des Newsletters verwendet wird. Diese Einwilligung kann jederzeit widerrufen werden.',
        disabled: 'Double-Opt-In deaktivieren',
        reminder: 'Remindermail bei fehlender Bestätigung einen Tag vor Veranstaltungsbeginn senden',
        mailTextInvalid: 'Der Mailtext darf nicht leer sein und muss den Platzhalter des Bestätigungslinks enthalten.',
        hasParticipants: 'Da bereits Teilnehmer registriert sind, können Sie die Double-Opt-In Einstellung nicht mehr verändern.'
      },
      email: {
        mailAfterRegistration: 'E-Mail nach Registrierung',
        pinOfEvent: 'PIN der Veranstaltung',
        showParticipantInput: 'Eingaben des Teilnehmers anzeigen',
        responseMail: 'Antwort E-Mail Adresse*',
        bcc: 'BCC Empfänger',
        subject: 'Betreff',
        text: 'Mailtext',
        reminderMail: 'Remindermail 1 Stunde und 1 Tag vor Veranstaltungsbeginn',
        selectMailTemplate: 'Wählen Sie eine Mailvorlage',
        answersAndPersonalData: 'Umfragen und persönliche Daten',
        answers: 'nur Umfragen',
        personalData: 'nur persönliche Daten',
        none: 'nicht anzeigen',
        dialOutWarning: 'Sie haben als Mailvorlage "Dial-Out-Konferenz" gewählt. Für diesen Konferenztyp ist eine Pflichtfeldabfrage der Rufnummer erforderlich, damit die Teilnehmer angerufen werden können.',
        dialOutInWarning: 'Sie haben als Mailvorlage "Dial-Out-Konferenz mit Dial-In Option" gewählt. Sie fragen die Rufnummer aber nicht als Pflichtfeld ab. Bitte beachten Sie, dass nur Teilnehmer angerufen werden, die ihre Rufnummer bei der Registrierung angegeben haben. Alle anderen Teilnehmer müssen sich selbst einwählen.'
      },
      general: {
        defaultName: 'Name / Bezeichner des Formulars',
        title: 'Überschrift der Veranstaltung',
        subtitle: 'Untertitel der Veranstaltung'
      },
      participation: {
        getParticipationData: 'Teilnehmerdaten erfassen',
        predefinedInputFields: 'Vordefinierte Eingabefelder',
        customInputField: 'Individuelle Eingabefelder',
        customField: 'Weiteres Zusatzfeld',
        requiredDescription: 'Wenn Umfrageergebnisse in den erweiterten Konferenzdetails angezeigt werde sollen, dann müssen “Name, E-Mail, Telefon” oder die “gut beraten ID” einheitlich in Anmelde- und Umfrageformular als Pflichfeld abgefragt werden. Im ersten Fall erfolgt das Matching über die E-Mail-Adresse.',
        customCheckbox: 'Individuelle Checkboxen',
        anotherCheckBox: 'Weitere Checkbox',
        commentField: 'Kommentarfeld',
        nameMail: 'Name, E-Mail',
        defaultInputFields: 'Standard Eingabefelder',
        dialOutRequired: 'in Dial-Out Konferenzen Pflicht'
      },
      privacy: {
        dataProtection: 'Datenschutzerklärung:',
        byBranding: '(vom Branding übernommen)',
        weblink: 'Weblink zur Datenschutzerklärung*',
        example: 'Beispiel: https://www.muster.de/datenschutz',
        contentResponsibility: 'Inhaltlich Verantwortlicher* (Name, Firma)',
        dsgvoDescription: 'Nach Artikel 13 DSGVO haben Sie als Verantwortlicher die Informationspflicht bzgl. der Erhebung von personenbezogenen Daten. Bitte tragen Sie nachstehend den Weblink zu Ihrer Datenschutzerklärung ein.',
        impressumDescription: 'gemäß § 18 Abs. 2 MStV. Die Darstellung erfolgt im Impressum des Formulars.',
        privacyImpressum: 'Datenschutz, Impressum'
      },
      endUrl: {
        title: 'Weiterleitung nach dem Event',
        description: 'Am Ende eines Online-Events können Sie Ihre Teilnehmer automatisch auf eine Webseite weiterleiten. Das kann Ihre Homepage, eine Produktseite oder eine beliebige andere Destination sein. Wenn Sie das Feld leer lassen, erfolgt keine Weiterleitung.',
        endUrlLabel: 'Weblink der Weiterleitung',
        placeholder: 'Beispiel: https://www.csn.gmbh'
      },
      questions: {
        noQuestions: 'Keine Fragen.',
        deleteQuestion: 'Frage löschen',
        explanation: 'Erläuterung der Frage',
        selectTypeOfQuestion: 'Wählen Sie die Art der Frage',
        questionTypeCannotBeChanged: 'Da bereits Teilnehmer registriert sind, kann der Fragetyp nicht mehr verändert werden.',
        bonusPoints: 'Bonus- bzw. Maluspunkte je richtiger oder falscher Antwort',
        evaluationInfo: 'Info zur Bewertung',
        noEvaluation: 'keine Bewertung gewünscht',
        answers: 'Antworten',
        answer: 'Antwort',
        correct: 'Richtig',
        atLeastTwoAnswers: 'Bitte fügen Sie mindestens zwei Antworten hinzu.',
        atLeastOneCorrect: 'Da die Frage mit Punkten versehen ist, geben Sie mindestens eine Antwort als richtig an.',
        anotherAnswer: 'Weitere Antwort',
        oneAnswer: 'Da die Frage mit Punkten versehen ist, geben Sie eine Antwort als richtig an.',
        pleaseAddSubpoint: 'Bitte fügen Sie einen Unterpunkt hinzu',
        newSubpoint: 'Neuer Unterpunkt',
        noNewSubpoints: 'Da bereits Teilnehmer registriert sind, können keine neuen Unterpunkte hinzugefügt werden.',
        noNewQuestions: 'Da bereits Teilnehmer registriert sind, können keine neuen Fragen hinzugefügt werden.',
        addQuestion: 'Frage hinzufügen',
        multipleChoice: 'Multiple Choice (Mehrfachauswahl)',
        singleChoice: 'Single Choice (Einfachauswahl)',
        textWithSubpoints: 'Textfrage mit Unterpunkten',
        textBox: 'Textfrage mit Antwortbox',
        titleQuestionN: 'Title Frage {number}'
      },
      speakers: {
        speakers: 'Referenten',
        speakerName: 'Referentenname'
      }
    },
    mailTemplate: 'Mailvorlage',
    mailText: 'Mailtext',
    replyToEmail: 'Antwort E-Mail Adresse',
    subject: 'Betreff',
    requiredAndEmail:
      'Pflichtfeld muss ausgefüllt werden und eine valide E-Mail-Adresse sein.',
    text: 'Text',
    placeholderInfo: 'Info zu Platzhaltern',
    invalidMail: 'Geben Sie eine gültige E-Mail-Adresse an.',
    sendTestMail: 'Testmail senden',
    toAllParticipants: 'an alle Teilnehmer',
    toSelectedParticipants: 'nur an Teilnehmer, die noch keins erhalten haben',
    testMailSuccess: 'Testmail erfolgreich verschickt!',
    sendMailSuccess: 'Mail erfolgreich verschickt!',
    certificateSetup: 'Zertifikat - Setup',
    certificateToSelected: 'Zertifikat an Teilnehmer versenden',
    certificateToAll: 'Zertifikate an alle Teilnehmer versenden',
    yourCertificate: 'Ihr Zertifikat zur Teilnahme',
    participationCertificate: 'Zertifikat zur Teilnahme',
    certificateAtThe: 'Zertifikat zur Teilnahme am',
    surveyModalDescription:
      'Möchten Sie eine Umfrage oder einen Multiple-Choice-Test in der Konferenz durchführen? Dann ordnen Sie hier das entsprechende Umfrageformular zu. Die erweiterten Konferenzdetails werden dann um die Umfrageergebnisse ergänzt, sofern eine "gut beraten ID" oder E-Mail-Adresse im Anmelde- und Umfrageformular abgefragt werden.',
    regModalDescription:
      'Nutzen Sie ein Anmeldeformular für die Registrierung der Teilnehmer? Dann ordnen Sie den Anmeldeformulartermin der Konferenz zu. Dadurch erscheinen die Teilnehmer als Gruppe in der Steuerung und können komfortabel zugeschaltet werden.',
    registrationForm: 'Anmeldeformluar',
    surveyForm: 'Umfrageformular',
    date: 'Termin',
    dates: 'Termine',
    selectDate: 'Termin auswählen',
    missingDate: 'Bitte wählen Sie einen Termin aus',
    formSuccess: 'Formular erfolgreich geändert',
    noTitle: 'Kein Titel',
    dateTitleNumber: `Titel Termin {number}`,
    assignedElsewhere: 'woanders zugeordnet',
    noForm: 'kein Formular',
    newRegForm: 'Neues Anmeldeformular',
    newSurveyForm: 'Neues Umfrageformular',
    brandings: 'Branding | Brandings',
    newBranding: 'Branding erstellen',
    noBranding: 'kein Branding',
    fileNotUploaded: 'Eine Datei konnte nicht hochgeladen werden. Bitte versuchen Sie es erneut.',
    topic: 'Thema',
    regCertificate:
      '<p>{ANREDE} {VORNAME} {NACHNAME},</p><p><br></p><p>anbei erhalten Sie Ihr Zertifikat zur Teilnahme an unserer Fortbildung am {DATUM}.</p><p><br></p><p>Mit freundlichen Grüßen,</p><p><br></p><p>{ACCOUNTINHABER}</p>',
    createNewRegForm: 'Erstellen Sie ein neues Anmeldeformular',
    createNewSurveyForm: 'Erstellen Sie ein neues Umfrageformular',
    customUrlParams: 'Benutzerdefinierte URL-Parameter',
    newForm: 'Neues Formular',
    activeForms: 'Aktives Formular | Aktive Formulare',
    currentForms: 'Aktuelles Formular | Aktuelle Formulare',
    oldForms: 'Altes Formular | Alte Formulare',
    outdatedForms: 'Abgelaufenes Formular | Abgelaufene Formulare',
    oldFormsDescription: 'Formulare, die seit 6 Monaten nicht mehr verändert oder genutzt wurden',
    oldRegsDescription: 'Formulare, die ausschließlich abgelaufene Termine enthalten',
    activeDates: 'Aktiver Termin | Aktive Termine',
    deleteOldParticipants: 'Alle alten Teilnehmer löschen',
    deleteOldParticipantsDates: 'Alle alten Termine und Teilnehmer löschen',
    deleteAllForms: 'Alle alten Formulare vollständig löschen',
    deleteAfterMonths: 'Löschung nach {months} Monaten',
    totalParticipantCount: 'Gesamtanzahl Teilnehmer',
    visitForm: 'Formular besuchen',
    lockForm: 'Formular sperren',
    unlockForm: 'Formular entsperren',
    duplicateForm: 'Formular duplizieren',
    editForm: 'Formular bearbeiten',
    blacklistOn: 'Blacklist: An',
    blacklistOff: 'Blacklist: Aus',
    turnOffBlacklist: 'Blacklist ausschalten',
    turnOnBlacklist: 'Blacklist einschalten',
    deleteParticipants: 'Teilnehmer löschen',
    deleteForm: 'Formular löschen',
    moreDates: 'weitere Termine',
    noDates: 'keine Termine',
    datesList: 'Datumsliste',
    duplicated: 'Formular erfolgreich dupliziert!',
    blacklistEnabled: 'Blacklist eingeschaltet',
    blacklistDisabled: 'Blacklist ausgeschaltet',
    editRegForm: 'Anmeldeformular bearbeiten',
    saveChanges: 'Änderungen speichern',
    savingChanges: 'Änderungen werden gespeichert',
    formHasErrors:
      'Bitte korrigieren Sie die rot markierten Fehler oder vervollständigen Sie die Seite.',
    noCertificate:
      'Sie müssen eine Vorlage für Teilnehmer-Zertifikate hochladen.',
    formSaved: 'Formular erfolgreich bearbeitet',
    formCreated: 'Formular erfolgreich erstellt',
    datesCount: 'Anzahl Termine',
    deleteAllParticipants: 'Alle Teilnehmer löschen',
    certificatesGenerating: 'Zertifikate werden generiert...',
    certificatesDownloading: 'Zertifikate werden heruntergeladen...',
    generatedCertificates: 'Zertifikate generiert',
    certDownloadStarted: 'Zertifikatversand gestartet...',
    certificatesSent: 'Zertifikate erfolgreich verschickt!',
    sendCertificates: 'Zertifikate versenden',
    sentCertificates: 'Zert. versendet',
    downloadCertificates: 'Zertifikate downloaden',
    downloadSimpleParticipants: 'Download (Name, Nummer)',
    downloadFullParticipants: 'Download (vollständig)',
    importParticipants: 'Teilnehmer importieren',
    toAdressbook: 'ins Adressbuch übernehmen',
    emailToParticipants: 'E-Mail an Teilnehmer',
    noRegForm: 'Kein Anmeldeformular vorhanden',
    noSubtitle: 'Keine Untertitel',
    simpleRegCsvTitle: 'Teilnehmer_{date} (Name-Nummer)',
    fullRegCsvTitle: 'Teilnehmer_{date} (vollständig)',
    doiVerified: 'Double-Opt-In bestätigt',
    doiNotVerified: 'Double-Opt-In bestätigt',
    noParticipants: 'Keine Teilnehmer vorhanden.',
    noParticipantsRegistered: 'Keine Teilnehmer registriert',
    nParticipantsRegistered: '{number} Teilnehmer registriert',
    usersLoading: 'Nutzer werden geladen...',
    birthdayOrId: 'Geburtsdatum (oder internes Personenkennzeichen)',
    educationName: 'Name der Bildungsmaßnahme',
    learningType: 'Lernart',
    learningContent: 'Lerninhalt',
    educationDuration: 'Bildungszeit in Minuten',
    internalBookingId: 'Interne Buchungs-ID',
    contactTitle: 'Ansprechpartner Titel',
    contactFirstname: 'Ansprechpartner Vorname',
    contactLastname: 'Ansprechpartner Nachname',
    contactPhone: 'Ansprechpartner Telefon',
    contactEmail: 'Ansprechpartner E-Mail',
    gutBeratenChanged: 'Gut Beraten erfolgreich aktualisiert!',
    gutBeratenIDVV: 'gut beraten ID (VV-ID)',
    editParticipant: 'Teilnehmer bearbeiten',
    minDurationNotReached:
      'Teilnehmer hat die erforderliche Teilnahmedauer nicht erreicht ({duration} Min) ',
    minDurationNotReachedByAnyone:
      'Kein Teilnehmer hat die erforderliche Teilnahmedauer erreicht ({duration} Min) ',
    doiNotConfirmed: 'Teilnehmer hat Double-Opt-In nicht bestätigt',
    certificateGenerating: 'Zertifikat wird generiert',
    sendCertificate: 'Zertifikat verschicken',
    certificateSentShort: 'Zertifikat verschickt',
    certificateSent: 'Zertifikat wurde verschickt',
    downloadCertificate: 'Zertifikat herunterladen',
    selectedParticipants: 'Ausgewählte Teilnehmer',
    registration: 'Registrierung',
    doi: 'DOI',
    userEdited: 'Teilnehmer erfolgreich bearbeitet',
    emailSent: 'E-Mail erfolgreich verschickt!',
    testEmailSent: 'Test E-Mail erfolgreich verschickt!',
    participantsAddedToAdressbook:
      'Teilnehmer erfolgreich ins Addressbuch übernommen.',
    createRegForm: 'Anmeldeformular erstellen',
    noCertificateError:
      'Sie müssen eine Vorlage für Teilnehmer-Zertifikate hochladen.',
    noSurveyForms: 'Keine Umfrageformulare vorhanden',
    lastParticipation: 'Letzte Teilnahme',
    editSurveyForm: 'Umfrageformular bearbeiten',
    editLimited: '(Eingeschränkt, da bereits Teilnehmer registriert sind)',
    createSurveyForm: 'Umfrageformular erstellen',
    surveyResults: 'Umfrage-Ergebnisse',
    statistic: 'Statistik',
    generateStatistic: 'Statistik generieren',
    downloadEvaluation: 'Auswertung downloaden',
    evaluation: 'Auswertung',
    noParticipantsPassed: 'Kein Teilnehmer hat bestanden',
    noSurvey: 'Umfrage nicht vorhanden',
    notEvaluated: 'nicht bewertet',
    notEvaluatedShort: 'n. B.',
    noEmail: 'Teilnehmer hat keine E-Mail-Adresse eingegeben',
    notEnoughPoints:
      'Teilnehmer hat die erforderliche Punktzahl nicht erreicht ({points}%)',
    notEnoughDuration:
      'Teilnehmer hat die erforderliche Teilnahmedauer nicht erreicht ({minutes} Min)',
    downloadSingleEvaluation: 'Einzelauswertung downloaden',
    didNotPass: 'Teilnehmer hat nicht bestanden',
    evaluationCertificate: 'Bewertung / Teilnehmer-Zertifikat',
    participantsCertificate: 'Teilnehmer-Zertifikat',
    certificateSubtitle:
      'nicht erforderlich, wenn ein Umfrageformular mit Zertifikat genutzt wird',
    schoolingLimitFrom: 'Ein Test gilt als bestanden ab einer',
    pointsFrom: 'Multiple Choice Punktzahl von',
    pointsAmount: 'Entspricht {percent} von {total} Punkten',
    durationOf: 'Teilnahmedauer von',
    certificateForParticipants: 'Zertifikat für Teilnehmer',
    certificate: 'Zertifikat',
    certificates: 'Zertifikate',
    certificateInfo:
      'Teilnehmer-Zertifikate können in Powerpoint individuell erstellt werden. Schauen Sie sich dazu unsere Vorlage oder die Erläuterung zu den Platzhaltern an.',
    pptTemplate: 'Powerpoint Vorlage',
    placeholder: 'Platzhalter',
    preview: 'Vorschau',
    certificateFootnotes:
      'Der E-Mail-Versand der Zertifikate kann nach Veranstaltungsende über die Teilnehmerliste freigegeben werden.',
    certificatePreview: 'Zertifikat-Vorschau',
    variableChanged: 'Variable wurde erfolgreich verändert!',
    startTimeChangedDeleteParts: 'Die Startzeit wurde verändert. Sollen die registrierten Teilnehmer gelöscht werden?',
    extras: 'Extras',
    hide: 'ausblenden',
    show: 'einblenden',
    lastRegistration: 'Letzte Anmeldung',
    notActivated: 'nicht aktiviert',
    countdownFrom: 'Countdown ab',
    dateDescription: 'Terminbeschreibung',
    allowRegistrationOnMultipleDates: 'Anmeldung an mehreren Terminen ermöglichen',
    addDate: 'Termin hinzufügen',
    nMinutesBeforeStart: '{minutes} Minuten vor Beginn',
    activationPeriod: 'Aktivierungszeitraum',
    urlCopied: 'URL kopiert',
    gutBeratenID: 'gut beraten ID (VV-ID)',
    labelExtraField: 'Label Zusatzfeld',
    extraField: 'Zusatzfeld',
    extraFieldN: 'Zusatzfeld {number}',
    labelCheckbox: 'Label Checkbox',
    checkboxN: 'Checkbox {number}',
    labelSubpoint: 'Label Unterpunkt',
    vvIdFormat: 'Format: JJJJMMTT-XXXXXX-XX',
    vvIdInvalid: 'VV-ID muss vorgegebenem Format entsprechen',
    fixErrorInForm: 'Bitte beheben Sie die Fehler im Form',
    checkbox: 'Checkbox',
    defaultSurveyText: '<p>{ANREDE} {VORNAME} {NACHNAME},</p><p><br></p><p>Sie haben in dem Multiple-Choice-Test {E_PUNKTE} von {G_PUNKTE} Punkten erreicht.</p><p><br></p><p>Herzlichen Glückwunsch, die Prüfung gilt somit als bestanden.</p><p><br></p><p>Mit besten Grüßen, Ihr*</p><p>{ACCOUNTINHABER}</p>',
    continueTo: 'Weiter zu {name}',
    registrationTime: 'Registrierungszeit',
    partnerID: 'PartnerID',
    selectDialInOrDialOut: 'Bitte schalten Sie mindestens "Einwahl" oder "Teilnehmer anrufen" ein.',
    gutBeratenExists: '"gut beraten" existiert',
    outlookCalendar: 'Outlook-Kalendereintrag',
    insertReplyMail: 'Bitte Antwort-E-Mail-Adresse eingeben!',
    participantDetails: 'Details zum Teilnehmer',
    reachedPoints: 'Erreichte Punkte',
    questionsCorrect: 'Fragen richtig beantwortet',
    passed: 'Bestanden',
    participation: 'Teilnahme',
    fromNPercent: 'ab {percent}%',
    questionNWithBox: 'Frage {questionNumber} (with answer box)',
    questionMultipleTextAndAnswer: 'Frage {questionNumber} (with subpoints)',
    questionSingleChoice: 'Frage {questionNumber} (single choice)',
    questionMultipleChoice: 'Frage {questionNumber} (multiple choice)',
    individualEvaluationFilename: 'Einzelauswertung-zur-Umfrage',
    excelLegend: {
      evaluationAndLegend: 'Bewertung und Legende',
      singleChoiceTitle: 'Informationen zur Bewertung von Single Choice Fragen (Einfachauswahl)',
      singleChoiceDescription: 'Bei Single-Choice Fragen ist die Bewertung leicht. Es wird mit einem einfachen Bonussystem gearbeitet: nur wer die korrekte Antwort angekreuzt hat, erhält die volle Punktzahl (ein Punkt oder je nach Gewichtung mehrere) für die Frage. Alle Prüflinge mit falscher oder fehlender Antwort erhalten keine Punkte.',
      multipleChoiceTitle: 'Informationen zur Bewertung von Multiple Choice Fragen (Mehrfachauswahl)',
      multipleChoiceDescription1: 'Bei einer Multiple-Choice-Frage ist ein Bonussystem unzureichend. Daher kommt hier ein Malussystem zum Einsatz. Im Malussystem gibt es auch für falsche Antworten eine Bewertung, allerdings in Form von negativen Punkten bzw. Punktabzügen. Bonuspunkte für korrekte Antworten (Ankreuzen von richtigen Antwortoptionen, Nicht-Ankreuzen von falschen) und Maluspunkte für falsche (Nicht-Ankreuzen von richtigen Antwortoptionen, Ankreuzen von falschen). Der Abschluss einer Aufgabe mit negativen Punkten ist ausgeschlossen, indem als niedrigste zu erreichende Punktzahl für eine Aufgabe Null angesetzt wird (sogenanntes „weiches Malussystem“).',
      multipleChoiceDescription2: 'Bonus- und Maluspunkte haben die gleiche Höhe. Die beitragsmäßig gleiche Wertung von falschen und richtigen Antworten ist das einfachste und am meisten verbreitete Verfahren.',
      multipleChoiceDescription3: 'Das Bewertungsverfahren ist an eine Anleitung der Hochschule Ludwigshafen am Rhein orientiert, die auf Empfehlungen der Technischen Universität München und der Universität Bern beruht.',
      example: 'Beispiel',
      multipleChoiceDescriptionExample: 'Bewertung einer Multiple-Choice-Frage bei zwei richtigen Antworten aus 5 möglichen Alternativen',
      marked: 'Markiert',
      notMarked: 'Nicht markiert',
      correct: 'Korrekt',
      incorrect: 'FALSE',
      else_: 'Sonst',
      legend: 'Legende',
      abbSingleChoice: 'SC = Single Choice Frage',
      abbMultipleChoice: 'MC = Multiple-Choice Frage',
      descriptionHead: 'Im Kopfbereich einer Prüfungsfrage wird der Typ (SC/MC) genannt.',
      scFullPoints: 'Bei SC Fragen finden Sie zusätzlich die volle Punktzahl für eine richtige Beantwortung.',
      mcTwoValues: 'Bei MC-Fragen sind 2 Werte notiert. Links die Bonus- bzw. Maluspunkte je Option',
      rightMaxPoints: 'und rechts die maximal erreichbare Punktzahl der Frage. Beispiel: 1/4',
      evaluationOnEnd: 'Die Gesamtauswertung einer Prüfung finden Sie rechts am Ende der Tabelle.',
      tableHeadAllPoints: 'Im Tabellenkopf wird die erreichbare Gesamtpunktzahl dargestellt und der von Ihnen gewählte',
      valuePassedFrom: 'Wert, ab wann eine Prüfung als Bestanden gilt',
      resultPerParticipant: 'Zusätzlich wird je Teilnehmer das Prüfungsergebnis berechnet'
    },
    reachedPointsResult: 'erreichte Punkte: {reached} von {total}',
    points: '{reached} von {achieved}',
    evaluationFilename: 'Auswertung-zur-Umfrage',
    surveyDetails: 'Details zur Umfrage',
    passedFrom: 'Bestanden ab',
    timestamp: 'Versendet am',
    addressDetails: 'Adressdaten',
    certificateTemplateUrl: 'https://static.csn{env}.de/download/Powerpoint-Zertifikat-Vorlage.pptx',
    informationOnEvaluationUrl: 'https://static.csn{env}.de/download/Informationen-zum-Bewertungssystem.pdf',
    urlExists: 'Das Webverzeichnis ist bereits vergeben',
    invalidUrl: 'Geben Sie eine gültige URL an. Beispiel: https://www.csn.gmbh',
    deadline: 'Anmeldeschluss',
    surveyStatistics: 'Umfragestatistik',
    customFields: {
      customFields: 'Individuelle Eingabefelder',
      newField: 'Neues Eingabefeld',
      noFields: 'Keine eigenen Eingabefelder',
      saveField: 'Eingabefeld speichern',
      name: 'Beschriftung',
      notice: 'Ausfüllhinweis (erscheint beim Mouseover)',
      noticeShort: 'Ausfüllhinweis (Tooltip)',
      defineField: 'Eingabefeld definieren',
      global: 'Globale Nutzung',
      titleExample: 'z.B. Vermittlernummer (6-stellig)',
      descriptionExample: 'z.B. Bitte geben Sie Ihre 6-Stellige Vermittlernummer ein',
      validation: 'Validierung',
      formatExample: 'z.B. ######',
      maxChars: 'max Zeichenzahl',
      chars: 'Zeichen',
      ignoreCase: 'Groß- & Kleinschreibung ignorieren (Ignore Case)',
      extendedOptions: 'Erweiterte Prüfmethoden',
      simpleOptions: 'Einfache Prüfmethoden',
      reservedChars: 'Reservierte Zeichen',
      hash: 'Hash',
      percent: 'Prozent',
      dot: 'Punkt',
      star: 'Stern',
      plus: 'Plus',
      questionMarkShort: 'Fragez.',
      pipe: 'Pipe',
      hashDescription: 'Eine Ziffer (0-9)',
      percentDescription: 'Ein beliebiger Groß- oder Kleinbuchstabe',
      dotDescription: 'Ein beliebiges Zeichen',
      starDescription: 'Null oder mehr Vorkommen des vorherigen Ausdrucks',
      plusDescription: 'Ein oder mehr Vorkommen des vorherigen Ausdrucks',
      questionMarkDescription: 'Null oder ein Vorkommen des vorherigen Ausdrucks',
      setDescription: 'Ein Zeichen, das in der Menge enthalten ist',
      notSetDescription: 'Ein Zeichen, das in der Menge nicht enthalten ist',
      charsExactDescription: 'Genau n Vorkommen des Vorherigen Ausdrucks',
      charsAtLeastDescription: 'Mindestens n Vorkommen des vorherigen Ausdrucks',
      charsBetweenDescription: 'Zwischen n und m Vorkommen des vorherigen Ausdrucks',
      pipeDescription: 'Alternativen (Entweder der vorherige Ausdruck oder der folgende Ausdruck',
      groupDescription: 'Eine Gruppe von Ausdrücken',
      position: 'Position',
      inputField: 'Eingabefeld',
      ignoreCaseShort: 'IC',
      usedInAfUf: 'Nutzung AF/UF',
      simpleExamplesModalTitle: 'Einfache Prüfmethoden - Beispiele',
      complexExamplesModalTitle: 'Erweiterte Prüfmethoden - Beispiele',
      exampleModalTexts: {
        hashDescription: 'Steht für 6 Ziffern',
        hashExample: 'z.B. 123456',
        percentDescription: 'Steht für 3 Groß- oder Kleinbuchstaben',
        percentExample: 'z.B. aBC',
        dotDescription: 'Steht für 2 beliebige Zeichen',
        dotExample: 'z.B. A1 oder 2!',
        anyDigitsDescription: 'Steht für keine oder mehrere Ziffern',
        anyDigitsExample: 'z.B. nichts oder 123',
        atLeastOneDigitDescription: 'Steht für eine oder mehrere Ziffern',
        atLeastOneDigitExample: 'z.B. 1 oder 1234567',
        optionalDigitDescription: 'Steht für keine oder eine Ziffer',
        optionalDigitExample: 'z.B. nichts oder 9',
        twoLettersAnyDigitsDescription: 'Steht für 2 Buchstaben und keine oder mehrere Ziffern',
        twoLettersAnyDigitsExample: 'z.B. AT, bD5, XY456778879',
        letterRegexDescription: 'Steht für einen Klein- oder Großbuchstaben ohne Umlaute',
        charRangeDescription: 'Steht 4,5,6 oder P',
        charSet: 'Steht für J oder N',
        notCharsDescription: 'Steht für ein Zeichen das nicht a,b,c, A, B oder C ist',
        notCharsExample: 'z.B. 5, X, $',
        digitsExactDescription: 'Steht für eine 6-stellige Ziffernfolge',
        digitsExactExample: 'z.B. 123456',
        digitsBetweenDescription: 'Steht für eine 4-6-stellige Ziffernfolge',
        digitsBetweenExample: 'z.B. 1234, 12345, 123456',
        digitsOrDescription: 'Steht für eine 4- oder 6-stellige Ziffernfolge',
        digitsOrExample: 'z.B. 1234, 123456',
        yesOrNoExpression: '(Ja)|(Nein)',
        yesOrNoDescription: 'Steht für Ja oder Nein'

      },
      helpDescription: 'Die Überprüfung, ob eine Eingabe dem gewünschten Format entspricht, geschieht in der Programmierung mit "<a href="https://wiki.selfhtml.org/wiki/JavaScript/Objekte/RegExp" target="_blank">Regulären Ausdrücken</a>". Wenn Sie bereits Erfahrung damit haben, dann können Sie Ihre Prüfformel selbst erstellen und ausprobieren. Andernfalls stehen wir Ihnen gerne mit Rat und Tat zur Seite.',
      helpTitle: 'Hilfe zur Validierung',
      needHelp: 'Ich brauche Hilfe bei der Einrichtung',
      previewTitle: 'Vorschau und Test',
      notValid: 'Dieser Ausdruck ist nicht gültig',
      valid: 'Dieser Ausdruck ist gültig',
      checkInput: 'Eingabe prüfen',
      helpMailSubject: 'Hilfe zur Einrichtung individueller Eingabefelder',
      helpMailText: 'Kundennummer: {customerId}\n' +
        'Benutzername: {username}\n' +
        'Name: {name}\n' +
        'Rufnummer: {phone}\n' +
        'E-Mail: {mail}\n' +
        '\n' +
        'Liebes CSN-Team,\n' +
        '\n' +
        'ich benötige Hilfe bei der Einrichtung eines individuellen Eingabefeldes:\n' +
        '\n' +
        'Meine bisherigen Eingaben lauten wie folgt:\n' +
        '\n' +
        'Beschriftung: {title}\n' +
        'Ausfüllhinweis: {tooltip}\n' +
        'Validierung (optional): {validation}\n' +
        'Maximale Zeichenzahl (optional): {maxChars}\n' +
        'Groß- und Kleinschreibung ignorieren (optional): {ignoreCase}\n' +
        '\n' +
        'Wenn Sie Hilfe bei der Erstellung der Validierungsformel benötigen, dann nennen Sie uns ein Beispiel für eine gültige Eingabe:\n' +
        '\n' +
        'Wir erstellen Ihnen das Eingabefeld mit der gültigen Formel direkt in Ihrem Account.\n' +
        '\n' +
        'Haben Sie darüber hinaus Fragen zu individuellen Eingabefeldern, dann schildern Sie uns diese hier in dieser Mail:',
      reservedCharsModalTitle: 'Reservierte Zeichen in der Validierung',
      reservedCharsModalText: 'In der Validierungsformel müssen folgende Zeichen "escaped" werden, um ihre spezielle Bedeutung innerhalb des regulären Ausdrucks zu deaktivieren und sie als normale Zeichen zu behandeln: <br><br> \\ ^ $ # % . | [ ] ( ) { } * + ? / <br><br>Das Escapen dieser Zeichen erfolgt durch Voranstellen eines Backslashes "\\"',
      validationTextTooLong: 'Die Eingabe ist zu lang',
      invalidPattern: 'Das Pattern ist nicht korrekt.',
      moveUp: 'Nach oben verschieben',
      moveDown: 'Nach unten verschieben',
      cantMakeLocal: 'Die globale Nutzung kann nicht deaktiviert werden, da das Feld in Formularen folgender Accounts genutzt wird: ',
      cantDelete: 'Dieses Feld kann nicht gelöscht werden, da es in Formularen folgender Accounts genutzt wird: ',
      cantDeleteUsed: 'Dieses Feld kann nicht gelöscht werden, da es in folgenden Formularen genutzt wird: ',
      cantDeleteGlobal: 'Dieses Feld kann nicht gelöscht werden, da es zentral von der Accountverwaltung Ihrer Firma bereitgestellt wurde.',
      cantEditGlobal: 'Dieses Feld kann nicht bearbeitet werden, da es zentral von der Accountverwaltung Ihrer Firma bereitgestellt wurde.',
      inputInvalid: '{name} entspricht nicht dem vorgegebenem Format. Fahren Sie mit der Maus über das Eingabefeld, um Ausfüllhinweise angezeigt zu bekommen.',
      usedBy: 'Benutzt von: ',
      fieldDeleted: 'Eingabefeld erfolgreich gelöscht.'
    }
  },
  brandings: {
    editBranding: 'Branding bearbeiten',
    newBranding: 'Neues Branding',
    saveBranding: 'Branding speichern',
    namePlaceholder: 'Brandingtitel eingeben',
    nameInvalid: 'Geben Sie einen Namen für das Branding ein.',
    global: 'Global',
    logo: 'Logo',
    dataTypes: 'Dateitypen: JPG, GIF, PNG, SVG',
    width: 'Breite: {width}px (empfohlen)',
    height: 'Höhe: variabel',
    left: 'links',
    middle: 'mitte',
    right: 'right',
    selectPicture: 'Bild auswählen',
    orMoveHere: 'oder hierher ziehen',
    banner: 'Banner',
    headerColors: 'Header-Farben',
    headerColorsDesc: 'Für Titel, Untertitel',
    text: 'Text',
    background: 'Hintergrund',
    accentColor: 'Schmuck-Farben',
    accentColorDesc: 'Für Termine, Icon, Button',
    areaCode: 'Länder-Vorwahl',
    legal: 'Rechtliches',
    privacy: 'Datenschutzerklärung',
    footer: 'Footer',
    preview: 'Vorschau',
    logoSaved: 'Logo gespeichert!',
    bannerSaved: 'Banner gespeichert!',
    brandingSaved: 'Branding gespeichert!',
    createBranding: 'Erstellen Sie ein neues Branding',
    noLogo: 'Kein Logo',
    noBanner: 'Kein Banner',
    brandingUsed: 'Branding wird in Formular(en) verwendet',
    deleteBranding: 'Branding löschen',
    brandingDeleted: 'Branding gelöscht',
    globalBranding: 'Globale Branding',
    usedInForms: 'In keinem Formular verwendet | In einem Formular verwendet | In {count} Formularen verwendet',
    noAccess:
      'Für eine professionelle Darstellung der Anmelde- und Umfrageformulare bietet CSN umfangreiche Branding-Funktionen an. Die Bedienung ist kinderleicht und bietet Ihnen viel Spielraum für Customizings in Ihrem Corporate Design oder das Ihrer Partner und Aktionen. Die Anzahl der Brandings ist nicht limitiert. Unser Grafik-Team unterstützt Sie bei der Erstellung des ersten Designs.',
    bookAccess: 'Zur Freischaltung der CSN Branding-Funktionen',
    previewTitle: 'Titel der Veranstaltung',
    previewDates: 'Terminübersicht',
    previewData: 'Persönliche Daten',
    previewRegister: 'Anmelden'
  },
  filter: {
    tableView: 'Tabellenansicht',
    cardsView: 'Kartenansicht',
    search: 'Suche',
    sort: 'Sortierung',
    newFormsDesc: 'neue Formulare zuerst',
    newFormsAsc: 'neue Formulare zuletzt',
    newCardsDesc: 'neue Visitenkarten zuerst',
    newCardsAsc: 'neue Visitenkarten zuletzt',
    newBackgroundsDesc: 'neue Hintergründe zuerst',
    newBackgroundsAsc: 'neue Hintergründe zuletzt',
    lastEditDesc: 'letzte Bearbeitung zuerst',
    lastEditAsc: 'letzte Bearbeitung zuletzt',
    alphabeticalAsc: 'alphabetisch aufsteigend (A-Z)',
    alphabeticalDesc: 'alphabetisch absteigend (Z-A)',
    entriesPerPage: 'Einträge/Seite',
    allEntriesPerPage: 'Alle Einträge/Seite',
    activate: 'aktivieren',
    deactivate: 'deaktivieren',
    activated: 'aktiviert',
    deactivated: 'deaktiviert',
    from: 'von',
    shown: 'Angezeigte',
    elements: 'Elemente',
    page: 'Seite',
    selectAll: 'Alle auswählen',
    deselectAll: 'Auswahl aufheben',
    invertSelection: 'Auswahl umkehren',
    changeSelection: 'Auswahl ändern'
  },
  operator: {
    servies: 'Operator-Services',
    selectedOperator: 'ausgewählter Operator',
    noOperatorSelected: 'Keine Operator ausgewählt',
    changeOperator: 'Operator ändern',
    selectOperator: 'Operator auswählen',
    noOperator: 'Kein Operator',
    changeSuccess: 'Operatorbuchung erfolgreich aktualisiert',
    bookOperator: 'Operator-Service buchen',
    editOperator: 'Operatorbuchung bearbeiten',
    noBookings: 'Keine Buchungen',
    conferenceBooked: 'Konferenz gebucht',
    conferenceNotBooked: 'Konferenz nicht gebucht',
    contactOperator: 'Operator kontaktieren',
    cancel: 'Stornieren',
    durationPreMeeting: 'Vorbespr.',
    cancelled: 'Operatorbuchung erfolgreich storniert!',
    cancelOperatorBook: 'Operator-Service buchen abbrechen',
    saveChanges: 'Änderungen speichern',
    bookNow: 'Jetzt buchen',
    backToOverview: 'Zurück zur Operator-Service Übersicht',
    bookingDescriptionTitle: 'Der "Rundum-Sorglos-Service" für unsere Kunden',
    bookingDescription:
      'Unser Operator-Team unterstützt Sie bei allen Themen rund um Ihre Telefon- und Multimediakonferenz. Wir stellen mit Ihnen die Konferenz ein, begleiten Sie als Operator bei der Anmoderation, leiten eine Frage- und Antwortrunde uvm. Stellen Sie sich Ihren Operator-Service individuell zusammen.',
    bookingChanged: 'Buchung erfolgreich geändert',
    booked: 'Operator-Service erfolgreich gebucht',
    alreadyAssigned:
      'Die ausgewählte Konferenz ist bereits einem anderen Operator-Service zugeordnet.',
    formInvalid:
      'Bitte korrigieren Sie die rot markierten Fehler oder vervollständigen Sie die Seite.',
    agents: {
      title: 'Referenten',
      description: 'Wer moderiert die Konferenz?',
      minOneAgent: 'Bitte fügen Sie mindestens einen Referenten hinzu.',
      agent: 'Referent'
    },
    date: {
      title: 'Konferenztermin',
      description: 'Wann benötigen Sie den Operator?',
      description2:
        'Automatische Vorbelegung, sofern unter Punkt 1 eine Konferenz oder ein Anmeldeformular-Termin zugeordnet wurde.',
      description3:
        'Beachten Sie bitte, dass je Buchung nur ein Termin zulässig ist. Bestehende Buchungen können als Vorlage genutzt werden.',
      expectedParticipants: 'erwartete Teilnehmerzahl',
      expectedParticipantsShort: 'erwartete Tln.',
      caMinute: 'ca. {min} Min.',
      caHour: 'ca. {hour} Std.',
      beforeMinute: '{min} Min. vorher'
    },
    organisation: {
      title: 'Organisation',
      description:
        'Wer organisiert die Konferenz und ist für Rückfragen erreichbar?',
      conferencePin: 'Konferenz-PIN',
      sixDigits: '6-stellig',
      participantPin: 'Telefon- und Datenkonferenz-PIN für Teilnehmer',
      language: 'Konferenz-Sprache',
      languageDescription:
        'Sprache für Systemansagen und Kommunikation mit dem Operator',
      organisator: 'Organisator',
      notes: 'Bemerkungen / Daten-Account',
      notesPlaceholder:
        'Tragen Sie hier bitte den Benutzernamen Ihres Daten-Accounts ein, sofern er vom Audio-Account abweicht. Haben Sie darüber hinaus Fragen oder Wünsche zur Konferenz?'
    },
    conference: {
      title: 'Konferenz-Planung',
      description: 'Wurde die Konferenz schon im Account vorbereitet?',
      description2:
        'Wurde die Konferenz bereits im System gebucht? Nutzen Sie ein Anmelde- oder Umfrageformular? Dann treffen Sie hier Ihre Auswahl.',
      assignConference: 'Konferenz zuordnen',
      room: 'Konferenzraum',
      noConference: 'keine Konferenz zugeordnet',
      assignRegForm: 'Anmeldeformular-Termin zuordnen',
      noDate: 'kein Termin zugeordnet',
      assignSurveyForm: 'Umfrageformular-Termin zuordnen',
      noForm: 'kein Formular zugeordnet'
    },
    services: {
      title: 'Services',
      titleLong: 'Operator-Services',
      description: 'Was kann der Operator für Sie tun?',
      conferenceInit: 'Initiierung der Konferenz',
      conferenceInitDescription: 'Setup der Konferenz und des Anmeldeformulars',
      systemTest: 'Systemtest mit Moderator(en)',
      systemTestDescription: 'Telefon, Headset, Mikro, Webcam, Browser, ...',
      preTalk: 'Vorbesprechung mit Moderator(en)',
      preTalkDescription: 'Abstimmung, Soundcheck und technischer Support',
      groups: 'Gruppenzuschaltung nach Vorbesprechung',
      groupsDescription: 'Die Anwahl beginnt erst nach Ihrer Freigabe',
      moderation: 'Anmoderation',
      moderationDescription: 'Begrüßung der Teilnehmer',
      qa: 'Frage-/Antwortrunde',
      qaDescription: 'Moderation und Freischaltung der Teilnehmer',
      recording: 'Aufnahme der Konferenz (7,95 Euro)',
      recordingDescription: 'Bereitstellung als MP3 File',
      hosting: 'CSN Video Erstellung, Hosting (75 Euro)',
      hostingDescription: 'und Synchronisation durch CSN (25 Euro)'
    },
    confType: {
      title: 'Konferenztyp',
      description: 'Welcher Konferenztyp soll moderiert werden?',
      selectMinOne: 'Bitte wählen Sie mindestens einen Typ aus.',
      dialOut: 'Dial-Out',
      dialOutDescription: 'Teilnehmer werden angerufen',
      dialIn: 'Dial-In',
      dialInDescription: 'Teilnehmer wählen sich selbst ein',
      presentation: 'Datenkonferenz',
      presentationDescription: 'Online-Präsentation, Screen Sharing, Chat ...',
      liveVideo: 'Live-Video',
      liveVideoDescription: 'Videobilder der Referenten'
    }
  },
  blacklist: {
    title: 'Blacklist',
    description:
      'Die Blacklist ist eine Sperrliste für Rufnummern und E-Mail-Adressen im Anmelde- und Umfragebereich. Zur Sperrung ganzer Bereiche wird * als Platzhalter unterstützt.',
    blockNumber: 'Rufnummer sperren',
    blockNumberExample: 'z.B.:  +49 1* oder +49 211 7306340',
    blockEmail: 'E-Mail sperren',
    blockEmailExample: 'z.B.: test@csn.de oder *@test.de',
    invalidNumber: 'Invalide Telefonnummer',
    successful: 'Blacklist erfolgreich aktualisiert!'
  },
  presentation: {
    speakerProfiles: {
      title: 'Visitenkarten',
      nCards: '{n} Karte | {n} Karten',
      saveSpeaker: 'Referent speichern',
      newSpeaker: 'Neuer Referent',
      newBusinessCard: 'Neue Visitenkarte',
      editSpeaker: 'Referent bearbeiten',
      editBusinessCard: 'Visitenkarte bearbeiten',
      firstAndLastname: 'Vor- & Nachname',
      position: 'Position',
      color: 'Farbe der Karte',
      noSpeakerProfiles: 'Es sind keine Referenten-Profile vorhanden.',
      sortAlphabetical: 'Sortierung alphabetisch',
      sortDateCreated: 'Zuletzt bearbeitet zuerst',
      sortLatest: 'Neuste zuerst',
      deleteConfirmModal: 'Möchten Sie wirklich die Visitenkarte von {name} löschen?',
      deleteConfirmModalOk: 'Visitenkarte löschen',
      deleteConfirmModalTitle: 'Visitenkarte löschen',
      companyLogo: 'Firmenlogo',
      photo: 'Foto',
      homepage: 'Homepage',
      customButtons: 'Individuelle Buttons',
      buttonDescription: 'Buttons sind Links auf externe Dienste wie z.B. Terminbuchung',
      nameOfButton: 'Name des Buttons',
      urlOfButton: 'URL des Buttons',
      addButton: 'Button hinzufügen',
      saveButton: 'Button speichern',
      buttonPlaceholder: 'Terminbuchung',
      activateProfile: 'Profil aktivieren',
      openProfile: 'Profil öffnen',
      photoCanBeMoved: 'Das Foto kann mit der Maus verschoben werden',
      infobox: 'Infobox',
      socialLinksTitle: 'Social Media Links',
      socialNetwork: 'Netzwerk',
      urlOfProfile: 'URL des Profils',
      addNetwork: 'Netzwerk hinzufügen',
      saveNetwork: 'Netzwerk speichern',
      selectNetwork: 'Netzwerk auswählen',
      wrongPlatform: 'Der Link passt nicht zur gewählten Platform oder enthält kein Profil',
      networks: {
        facebook: 'Facebook',
        instagram: 'Instagram',
        vimeo: 'Vimeo',
        x: 'X',
        xing: 'Xing',
        youtube: 'YouTube',
        linkedin: 'LinkedIn'
      },
      deleteSpeaker: 'Visitenkarte löschen',
      businessCard: 'Visitenkarte',
      urlTitle: 'URL',
      copyUrl: 'Url kopieren',
      noSpecialChars: 'Es sind nur Buchstaben, Zahlen und _.- erlaubt',
      invalidUrl: 'Ungültige URL',
      selectPlatform: 'Bitte wählen Sie eine Platform',
      extendedProfile: 'Erweitertes Profil',
      extendedProfilesDescription: 'Erweiterte Profile können während eines Events vom Teilnehmer eingesehen werden. Sie sind aber auch darüber hinaus im Internet, unter der von Ihnen gewünschten URL, erreichbar.',
      urlChanged: 'Bitte beachten Sie, dass bei einer Änderung des URL-Pfads der ursprüngliche QR-Code nicht mehr funktioniert. Dies könnte relevant sein, wenn Sie den QR-Code z.B. in eine E-Mail-Signatur integriert haben.',
      resetUrl: 'Zurück zum ursprünglichen URL-Pfad',
      saveToTest: 'Zum Testen der Funktion muss die Visitenkarte gespeichert werden',
      lineBreakDescription: 'Zeilenumbruch mit "|"',
      addressPlaceholder: 'Musterstraße 100, D-40213 Düsseldorf',
      homepagePlaceholder: 'https://www.muster.de'
    },
    liveVideoBackgrounds: {
      liveVideoBackgrounds: 'Live-Video-Hintergründe',
      description: 'Hintergrundbilder für Konferenzen',
      nImages: '{count} Bild | {count} Bilder',
      addNewImage: 'Neues Bild hinzufügen',
      editTitle: 'Namen des Hintergrundes bearbeiten',
      backgroundImage: 'Hintergrundbild',
      title: 'Name des Bildes',
      deletedToast: 'Hintergrund erfolgreich gelöscht',
      deleteConfirmModalOk: 'Hintergrund löschen',
      deleteConfirmModalTitle: 'Live-Video-Hintergrund löschen',
      deleteConfirmModalText: 'Möchten Sie wirklich den Live-Video Hintergrund löschen?',
      deleteMultipleConfirmModalOk: 'Hintergründe löschen',
      deleteMultipleConfirmModalTitle: 'Live-Video-Hintergründe löschen',
      deleteMultipleConfirmModalText: 'Möchten Sie wirklich die ausgewählten Live-Video Hintergründe löschen?',
      createModalDescription: 'Für optimale Ergebnisse ein Bild im 16:9 Format wählen mit einer Auflösung von mindestens 1280 x 720 Pixel',
      ratioWarning: '<strong>Achtung</strong>: Das angegebene Bild ist nicht im 16:9 Format und wird zugeschnitten.',
      sizeWarning: '<strong>Achtung</strong>: Bild entspricht nicht den Qualitätsstandards!',
      imageUploadFailed: 'Das Bild konnte nicht verarbeitet werden.',
      createYourOwn: 'Erstellen Sie Ihren eigenen Video-Hintergrund',
      minimumResolution: 'Mindestauflösung: {x} x {y} Pixel',
      selectedBackgrounds: 'Ausgewählte Hintergründe'
    }
  },
  days: {
    mon: 'Montag',
    tue: 'Dienstag',
    wed: 'Mittwoch',
    thu: 'Donnerstag',
    fri: 'Freitag',
    sat: 'Samstag',
    sun: 'Sonntag'
  },
  months: {
    jan: 'Januar',
    feb: 'Februar',
    mar: 'März',
    apr: 'April',
    may: 'Mai',
    jun: 'Juni',
    jul: 'Juli',
    aug: 'August',
    sep: 'September',
    oct: 'Oktober',
    nov: 'November',
    dec: 'Dezember'
  },
  login: {
    namePlaceholder: 'Vor- und Nachname',
    emailPlaceholder: 'Benutzername oder E-Mail Adresse',
    interested: 'Interesse geweckt?',
    invalidLogin: 'Sie versuchen sich als {loginMode} einzuloggen.',
    offer: 'Der erste Termin geht auf uns',
    noCustomerYet: 'Noch kein Kunde?',
    here: 'Hier',
    moreInformation: 'bekommen Sie weitere Informationen.',
    slides: {
      testNow: 'Jetzt kostenlos testen',
      inhouse: 'Inhouse',
      customerService: 'Kundenservice',
      brilliant: 'Brilliante',
      quality: 'Sprachqualität',
      premium: 'Premium',
      operator: 'Operator Service',
      intuitive: 'Intuitive',
      experience: 'Nutzerführung',
      one: {
        header: 'Willkommen bei CSN',
        gutBeraten: {
          text: '„gut beraten“ Schulungen',
          description: 'Wir eliminieren Bürokratie'
        },
        webinar: {
          text: 'Webinare',
          description: 'Von Registrierung bis zum Test'
        },
        livestream: {
          text: 'Professionelle Livestreams',
          description: 'HD-Qualität aus dem TV-Studio'
        },
        calls: {
          text: 'Vertriebs- und Recruitingcalls',
          description: 'Bewerben Sie Ihre Produkte'
        },
        events: {
          text: 'Virtuelle Events',
          description: 'Wir erfüllen Ihre Wünsche'
        }
      },
      two: {
        header: '„gut beraten“ Schulungen über CSN',
        text1: 'Begeistern Sie Ihre Teilnehmer durch ...',
        bulletPoints: {
          one: '<span class="text-primary font-weight-medium">abwechslungsreiche</span> Schulungen',
          two: 'eine <span class="text-primary font-weight-medium">schnelle Minutengutschrift</span>',
          three: 'ein individuelles <span class="text-primary font-weight-medium">Erfolgszertifikat</span>'
        },
        text2: 'Alles <span class="text-primary font-weight-medium">automatisiert</span> über unsere Plattform.'
      },
      three: {
        header: 'Effiziente Webinare',
        bulletPoints: {
          one: 'Attraktive Vermarktung über Anmeldeformulare',
          two: 'Spannende Schulungsinhalte',
          three: 'Wissenstests mit automatischer Auswertung nach Universitätsstandard',
          four: 'Individuelle Teilnehmer-Zertifikate'
        }
      },
      four: {
        header: 'HD-Livestreams direkt aus dem TV-Studio',
        text1: 'Maximieren Sie Ihre Überzeugungskraft, ohne Netz und doppelten Boden.',
        text2: 'Sie haben kein eigenes Studio?<br>Wir helfen Ihnen weiter!'
      },
      five: {
        header: 'Wir sind für Sie da',
        uncompromising: {
          text: 'Kompromisslos',
          description: 'Wir erfüllen Ihre Wünsche'
        },
        individualization: {
          text: 'Individualisierbarkeit',
          description: 'Auf Wunsch in Ihrem Design'
        },
        training: {
          text: 'Kostenlose Schulungen',
          description: 'Für Neukunden und Anwender'
        },
        operatorService: {
          text: 'Premium Operator Service',
          description: 'Den Rest übernehmen wir'
        },
        standards: {
          text: 'Höchste Standards',
          description: 'Datenschutz steht im Fokus'
        }
      },
      six: {
        header: 'Unsere Referenzen'
      }
    },
    login: 'Login',
    pin: 'Pin',
    errors: {
      invalidUsernameOrPassword: 'Benutzername oder Passwort ungültig.',
      falseName: 'Ihr Name muss mindestens zwei Zeichen enthalten!',
      noPresentation: 'Präsentation existiert nicht.',
      maxLimit: 'Login nicht möglich, da das Teilnehmerlimit für diese Veranstaltung erreicht wurde.',
      notStarted: 'Die Präsentation wurde noch nicht gestartet!',
      sessionLocked: 'Der Konferenzraum wurde vom Moderator gesperrt.',
      insertNameAndPin: 'Bitte Name und PIN eingeben.'
    },
    passwordChanged: 'Passwort erfolgreich geändert!'
  }
};
